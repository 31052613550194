import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent as SpecialistAppComponent } from 'projects/specialist/src/app/app.component';
import { AppComponent as AdminAppComponent } from'projects/admin/src/app/app.component';

import { LandingComponent } from './components/landing/landing.component';
import { AboutComponent } from './components/about/about.component';
import { IndustriesComponent } from './components/industries/industries.component';
import { LandingServicesComponent } from './components/landing-services/landing-services.component';
import { PlasticsComponent } from './components/industry components/plastics/plastics.component';
import { PlayroomComponent } from 'projects/specialist/src/app/components/specialist/playroom/playroom.component';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'About', component: AboutComponent },
  { path: 'Plastic', component: PlasticsComponent },
  { path: 'Services', component: LandingServicesComponent },
  { path: 'specialist-signup', component: SpecialistAppComponent },
  { path: 'admin-signin', component: AdminAppComponent },
  { path: 'playroom', component: PlayroomComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
