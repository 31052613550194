import { Component, OnInit } from '@angular/core';
import { FormGroup ,FormBuilder,Validators} from '@angular/forms';
declare var $: any;
import { FaqService} from 'src/app/services/faq/faq.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
@Component({
  selector: 'app-playroom',
  templateUrl: './playroom.component.html',
  styleUrls: ['./playroom.component.scss']
})
export class PlayroomComponent implements OnInit {
 
  mobile = false;
  isOpened = false;
  queryForm :FormGroup;
  faqs = [];
  industries = 0;
  activeUser;

  faqIndex;
  userDashboard :boolean = false;
  
  filterIndex = null;

  constructor(private formBldr : FormBuilder , private faqSrvc : FaqService,private authSrvc : AuthService, private router :  Router,  private userSrv: UserService) { }

  ngOnInit() {
    
    this.FAQ();
    if (window.innerWidth < 680) {
      this.mobile = true;
    }
    $(window).scroll( function () {

      var wScroll = $(window).scrollTop();
      
      if (wScroll >= 10) {
        $('.nav-wrapper').addClass('sticky-nav');

      } else {
        $('.nav-wrapper').removeClass('sticky-nav');

      }

    });
    this.queryForm = this.formBldr.group({

      subject: [
        null,
        [
          Validators.required,
          Validators.email
        ]
      ],
      message: [
        null,
        [
          Validators.required,
        ]
      ],

    });
  }

  // getLoggedInUser() {
  //   this.userSrv.getUser().subscribe(res => {
  //     this.activeUser = res;
  //     console.log(this.activeUser);
  //   })

  //   if(this.activeUser.role == 'SPECIALIST'){
  //     this.userDashboard = true
  //   }else{
  //     this.userDashboard = false
  //   }

  // }

  FAQ(){
    this.faqSrvc.getFAQ().then(resp=>{
      resp.faqs.forEach(element => {
        let answer = element.answer.split('#');
        if(element.page === "expert"){
          this.faqs.push(
            {
              questions: element.question,
              answer: answer,
              list: element.list ? element.list.items : null
            }
          );
        }
      });
    })
  }
  toggleMobileMenu() {

    if (this.isOpened) {
      this.isOpened = false;
    } else {
      this.isOpened = true;
    }
  }
  onValChange(event) {
    this.industries = event;

    console.log(event);
    console.log(this.industries);
  }

  logOut() {
    this.authSrvc.signout().then(
        resp => {
            this.router.navigate(['/']);
        }
    )
}

sendEmail() {

  let emailObject = {
    replyTo: this.activeUser.emailAddress,
    name: this.activeUser.firstName + " " + this.activeUser.lastName,
    subject: this.queryForm.value.subject,
    message: this.queryForm.value.message,
  }

  console.log(emailObject);
  console.log("I am clicked");
  this.faqSrvc.sendEmail(emailObject).then(data => {
    console.log(data)

  }).catch(error => {
    console.log(error);

  });
}
 
}

