import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'projects/specialist/src/app/services/user/user.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';


@Component({
  selector: 'app-edit-employment',
  templateUrl: './edit-employment.component.html',
  styleUrls: ['./edit-employment.component.scss']
})
export class EditEmploymentComponent implements OnInit {

  workHistoryForm: FormGroup;

  workExperince;
  duties = [];
  duty;
  employmentId;
  userId



  constructor(
    private userSrvc: UserService,
    private frmBldr: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {
    this.initFormGroups();

  }

  ngOnInit() {
    console.log("=======================================", this.data);
    this.workExperince = this.data;
    this.employmentId = this.workExperince.id;
    this.userId = this.workExperince.user.id;

    this.workHistoryForm.setValue({
      companyName: this.workExperince.company,
      numberOfYears: this.workExperince.duration,
      position: this.workExperince.position,
    });

    this.duties = this.workExperince.duties ? this.workExperince.duties : [];
  }

  addToQueue() {
    this.duties.unshift(
      this.duty
    );
    this.duty = null
  }
  removeItem(i) {
    this.duties.splice(i, 1)
  }

  updateEmp() {
    let employment =
    {
      userId: this.userId,
      duration: parseInt(this.workHistoryForm.value.numberOfYears),
      company: this.workHistoryForm.value.companyName,
      position: this.workHistoryForm.value.position,
      duties: this.duties,
    }
    this.userSrvc.updateEmployment(this.employmentId, employment).then(result => {
      this.workExperince = result;
      console.log(this.workExperince)
    }).catch(
      error => {
        console.log(error);
      }
    );
  }

  initFormGroups() {


    this.workHistoryForm = this.frmBldr.group({
      companyName: [null,],
      numberOfYears: [null,],
      position: [null,],

    });
  }

}
