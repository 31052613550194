import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from 'projects/specialist/src/app/services/user/user.service';

@Component({
  selector: 'app-edit-qualification',
  templateUrl: './edit-qualification.component.html',
  styleUrls: ['./edit-qualification.component.scss']
})
export class EditQualificationComponent implements OnInit {

  workHistoryForm: FormGroup;

  qualification;
  duties = [];
  duty;
  qualificationId;
  userId



  constructor(
    private userSrvc: UserService,
    private frmBldr: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {
    this.initFormGroups();

  }

  ngOnInit() {
    console.log("=======================================", this.data);
    this.qualification = this.data;
    this.qualificationId = this.qualification.id;
    this.userId = this.qualification.user.id;

    this.workHistoryForm.setValue({
      companyName: this.qualification.company,
      numberOfYears: this.qualification.duration,
      position: this.qualification.position,
    });

  }

  updateEmp() {
    let employment =
    {
      userId: this.userId,
      duration: parseInt(this.workHistoryForm.value.numberOfYears),
      company: this.workHistoryForm.value.companyName,
      position: this.workHistoryForm.value.position,
      duties: this.duties,
    }
    this.userSrvc.updateQualifications(this.qualificationId, employment).then(result => {
      this.qualification = result;
      console.log(this.qualification)
    }).catch(
      error => {
        console.log(error);
      }
    );
  }

  initFormGroups() {


    this.workHistoryForm = this.frmBldr.group({
      companyName: [null,],
      numberOfYears: [null,],
      position: [null,],

    });
  }

}
