import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { SpecialistComponent } from './components/specialist/specialist.component';
import { PlayroomComponent } from './components/specialist/playroom/playroom.component';
import { DashboardComponent } from './components/specialist/dashboard/dashboard.component';
import { ProfileComponent } from './components/specialist/dashboard/profile/profile.component';
import { EditComponent } from './components/specialist/dashboard/edit/edit.component';
import { OverviewComponent } from './components/specialist/dashboard/overview/overview.component';
import { ScheduleComponent } from './components/specialist/dashboard/schedule/schedule.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialComponentsModule } from 'src/app/material components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatChipsModule } from '@angular/material/chips';
import { TermsComponent } from './components/terms and conditions/terms/terms.component';
import { BuildProfileComponent } from './components/specialist/build-profile/build-profile.component';
import { SpecialistDialogModalComponent } from './modal/specialist-dialog-modal/specialist-dialog-modal.component';
import { EditEmploymentComponent } from './components/specialist/employment/edit-employment/edit-employment.component';
import { EditQualificationComponent } from './components/specialist/qualification/edit-qualification/edit-qualification.component';
import { MatSnackBarModule } from '@angular/material';
import { ProfilePictureComponent } from './modal/profile-picture/profile-picture.component';
import { UpdateProfileComponent } from './modal/update-profile/update-profile.component';
import { ResetpasswordComponent } from './modal/resetpassword/resetpassword.component';
import { ProffessionalRegistrationComponent } from './modal/proffessional-registration/proffessional-registration.component';
import { MembershipOfAssociationComponent } from './modal/membership-of-association/membership-of-association.component';
import { ProjectCompletedComponent } from './modal/project-completed/project-completed.component';
import {MatTableModule} from '@angular/material/table';
import { DutiesComponent } from './modal/duties/duties.component';
import {MatGridListModule} from '@angular/material/grid-list';



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SpecialistComponent,
    PlayroomComponent,
    DashboardComponent,
    ProfileComponent,
    EditComponent,
    OverviewComponent,
    ScheduleComponent,
    TermsComponent,
    BuildProfileComponent,
    SpecialistDialogModalComponent,
    EditEmploymentComponent,
    EditQualificationComponent,
    ProfilePictureComponent,
    UpdateProfileComponent,
    ResetpasswordComponent,
    ProffessionalRegistrationComponent,
    MembershipOfAssociationComponent,
    ProjectCompletedComponent,
    DutiesComponent,
   



  ],
  entryComponents:[
    SpecialistDialogModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialComponentsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTableModule,
    MatGridListModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export class SpecialistSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: []
    }
  }
}