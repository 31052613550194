import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service'
import { MatSnackBar, ErrorStateMatcher } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-proffessional-registration',
  templateUrl: './proffessional-registration.component.html',
  styleUrls: ['./proffessional-registration.component.scss']
})
export class ProffessionalRegistrationComponent implements OnInit {
  ProffessionalRegistrationForm: FormGroup;
  constructor(private frmBldr: FormBuilder, private usrSrv: UserService,  private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  selectedProfessionalDoc = null;
  professionalFile = null;
  load = false;

  activerUser;
  ngOnInit() {

    this.usrSrv.getUser().subscribe(resp => {
      this.activerUser = resp;
      console.log(resp)
    })
    this.ProffessionalRegistrationForm = this.frmBldr.group({
      ProffessionalBodyName: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2)
        ]
      ],
      QualificationName: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2)

        ]],
      proFile: [null,
        [
        ]],
    });
  }
  getProfessionalPresignedurl(): Promise<any> {


    return new Promise((resolve, reject) => {

      this.usrSrv.getProfessionalPresignedurls(this.selectedProfessionalDoc, "professionalRegistration").then(presignUrl => {
        //console.log(presignUrl);

        var formData: any = new FormData();

        Object.keys(presignUrl['presignedPost']['fields']).forEach(key => {
          formData.append(key, presignUrl['presignedPost']['fields'][key])
        });

        this.selectedProfessionalDoc.key = presignUrl.id;
        formData.append('file', this.selectedProfessionalDoc.file);
        //console.log("formdata", formData);
        this.usrSrv.professionalUploadFile(presignUrl.presignedPost.url, formData).then(
          data => {
            //console.log(data);

            resolve(presignUrl);
          }, error => {
            //console.log(error);
            reject(error)

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });
  }
  uploadProfessionalDocumet() {


  }


  onProfessionSelectedDocuments(event) {
    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedProfessionalDoc = {
      file: file,
      fileName: filename,
      fileExtension: extension,
    }
    console.log(this.selectedProfessionalDoc)
  }

  submitProffessionalRegistration() {
    this.load = true;

    if (this.selectedProfessionalDoc != null) {

      this.getProfessionalPresignedurl().then(data => {

        this.professionalFile = {
          key: data.id,
          fileName: this.selectedProfessionalDoc.fileName,
          extension: this.selectedProfessionalDoc.fileExtension
        }
        let ProoffessionaRegistration = {
          userId: this.activerUser.id,
          professional: this.ProffessionalRegistrationForm.value.QualificationName,
          professionalBody: this.ProffessionalRegistrationForm.value.ProffessionalBodyName,
          professionalFile: this.professionalFile,
        };

        this.usrSrv.createProffessionalRegistration(ProoffessionaRegistration).then(data => {
          console.log(data);
          this.load = false;
          this.myAlert("Professional Registraion Submited success updated successfully");
          this.closeDialog()
        })


        //console.log(this.user.slaObject);
        // let userId = this.user.id;
        // this.userSrvc.updateUser(userId, this.user).then(
        //   resp => {
        //     //console.log(resp);
        //     this.loading = false;
        //     this.myAlert("CV updated successfully");

        //   }
        // ).catch(
        //   error => {
        //     //console.log(error);

        //     this.myAlert("Your details could not be updated, please try again later");
        //     this.loading = false;

        //   }
        // );
      }).catch(
        error => {
          //console.log(error);

          this.myAlert("Your details could not be updated, please try again later");
          this.load = false;

        }
      );
    }
    // else {
    //   let ProoffessionaRegistration = {
    //     userId: this.activerUser.id,
    //     professional: this.ProffessionalRegistrationForm.value.QualificationName,
    //     professionalBody: this.ProffessionalRegistrationForm.value.ProffessionalBodyName,
    //     professionalFile: null,
    //   };

    //   this.usrSrv.createProffessionalRegistration(ProoffessionaRegistration).then(data => {
    //     console.log(data);
    //     this.load = false;
    //       this.myAlert("Professional Registraion Submited success updated successfully");
    //   })
    // }






  }
  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }
  // // openProffessionalRegistrationDialog(): void {
  //   let dialogReff = this.dialog.open(ProffessionalRegistrationComponent, {
  //      height: '500px',
  //      width: '600px',
     
  //    });
 
  // //  }
  closeDialog(){
    this.dialog.closeAll()
  }
}
