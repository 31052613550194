import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { SpecialistsService } from '../../services/specialists/specialists.service';
import { MatSnackBar, ErrorStateMatcher } from '@angular/material';


@Component({
  selector: 'app-specialist-modal',
  templateUrl: './specialist-modal.component.html',
  styleUrls: ['./specialist-modal.component.scss']
})
export class SpecialistModalComponent {
  user;
  loading = true;
  load = false;
  qualifications;
  downloading = false;
  employment;
  SpecialistSkills = [];
  ServiceTypes;
  Departments;
  accountstatus = false
  displayPicture = null;
  base64 = null;
  public selectedVal: string;
  AccountStatusForm: FormGroup;
  isChecked = false;
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private specialistService: SpecialistsService, private formBuilder: FormBuilder,  private _snackBar: MatSnackBar
  ) {


    this.formGroup = this.formBuilder.group({
      enableWifi: ''

    });

    this.user = data;
    console.log(this.user.specialistObj.idObject);

    this.specialistService.getQualifications(this.user.specialistObj.id).then(
      resp => {
        console.log(resp);
        this.qualifications = resp['qualifications'];

        console.log(this.qualifications)


        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).catch(
      error => {
        console.log(error);
        this.loading = false;
      }
    );

    if (this.user.specialistObj == null) {
      //this.dialog.open(UpdateProfileComponent);
    } else {
      this.user.specialistObj = this.user.specialistObj;

      if (this.specialistService.base64Uri['_value'] != null && this.user.specialistObj.profilePicture != null) {
        this.specialistService.getProfilePicture().subscribe(
          resp => {
            //console.log("variable changed");

            this.base64 = resp
          }
        );
      } else if (this.user.specialistObj.profilePicture != null) {
        ////console.log(student.studentNumber);
        this.base64 = localStorage.getItem(this.user.specialistObj.id);

        this.specialistService.getUploadedDocument(this.user.specialistObj.profilePicture.key).then(
          data => {

            this.base64 = 'data:image/jpeg;base64,' + data;
          }
        )

      }

    }


    this.SpecialistSkills = this.user.specialistObj.skills;
    console.log(this.SpecialistSkills);
  }

  ngOnInit() {
    console.log(this.user);
    this.getEmployements();

    //Populate the status value in the form field tomorrow.
    //this.setAccountStatusFormValues();



    this.AccountStatusForm = this.formBuilder.group({
      AccountStatus: [
        null],
      rejectionMessage: [null
      ]
    });


  }

  setAccountStatusFormValues() {
    this.AccountStatusForm.setValue({
      AccountStatus: this.user.specialistObj.status,
    });
  }

  download(file) {
    console.log(file);
    this.downloading = true;
    this.specialistService.getUploadedDocument(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }

  downloadQualifications(file) {
    console.log(file);
    this.downloading = true;
    this.specialistService.getUploadedQualifications(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }


  previewOnTab(data) {
    var type = 'application/pdf';
    let blob = null;
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, type));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
    this.downloading = false;
  }

  pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  getEmployements() {
    this.specialistService.GetEmployement(this.user.specialistObj.id).then(resp => {
      this.employment = resp;
      this.employment = this.employment.employments;
      console.log(this.employment);
    })
  }


  AccountVerificationStatus;

  SelectedAccountStatus(event: MatSelectChange) {
    console.log(event);
    this.AccountVerificationStatus = event.value;
  }

  verification() {
    this.load = true
    console.log(this.AccountVerificationStatus);
    let id = this.user.specialistObj.id
    this.user.specialistObj.status = this.AccountVerificationStatus;

    this.specialistService.updateUser(id, this.user.specialistObj).then(data => {
      let response = data;
      console.log(response.status);

      let welcomeObject = {
        mailTo: this.user.specialistObj.emailAddress,
        name: this.user.specialistObj.firstName + " " + this.user.specialistObj.lastName,
       
      }
      if (response.status === "VERIFIED") {
        this.specialistService.sendWelcomePack(welcomeObject).then(resp => {
          this.load = false
          this.myAlert("Verfication updated successfully and email notification sent");
        })
      }
      else if (response.status === "NOT_VERIFIED") {
     
        let rejectObjec = {
          mailTo: this.user.specialistObj.emailAddress,
          message :"Hi"+ " "+this.user.specialistObj.title+" "+this.user.specialistObj.firstName +" " +this.user.specialistObj.lastName+ "<br>your application was not successfull due to the following reasons:<br>"+ this.AccountStatusForm.value.rejectionMessage +" <br> <br>" +"Kind Regards <br> YB Team"
        }
    
        this.specialistService.sendRejectionPack(rejectObjec).then(resp => {
          this.load = false;
          this.myAlert("Verfication updated successfully and email notification sent");
        })
      }else if (response.status === "PENDING_VERIFICATION") {
        this.load = false
          this.myAlert("Verfication updated successfully and email notification sent");
      }

    });
  }

  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }

}
