import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './components/landing/landing.component';
import { MaterialComponentsModule } from '././material components/material-components/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpecialistSharedModule } from 'projects/specialist/src/app/app.module';
import firebase from 'firebase/app';
import { AboutComponent } from './components/about/about.component';
import { IndustriesComponent } from './components/industries/industries.component';

import { HttpClientModule } from '@angular/common/http';

import { LandingServicesComponent } from './components/landing-services/landing-services.component';
import { AdminSharedModule } from 'projects/admin/src/app/app.module';
import { IndustriesDialogComponent } from './modals/industries-dialog/industries-dialog.component';
import { PlasticsComponent } from './components/industry components/plastics/plastics.component';
import { MatSnackBarModule } from '@angular/material';

const firebaseConfig = {
  apiKey: 'AIzaSyBeweCFQEsy_IU2wxzNBIBcHk3FCzEylUs',
  authDomain: 'yabatho-c7937.firebaseapp.com',
  databaseURL: 'https://yabatho-c7937.firebaseio.com',
  projectId: 'yabatho-c7937',
  storageBucket: 'yabatho-c7937.appspot.com',
  messagingSenderId: '1074567287268',
  appId: '1:1074567287268:web:11f7d9e3f69286602f77f1',
  measurementId: 'G-CFEQQZQWGH'
};

firebase.initializeApp(firebaseConfig);


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutComponent,
    IndustriesComponent,
  
    LandingServicesComponent,
  
    IndustriesDialogComponent,
  
    PlasticsComponent,
  ],
  entryComponents:[
    IndustriesDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    SpecialistSharedModule.forRoot(),
    AdminSharedModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
