import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../services/user/user.service';
import { FormGroup, FormBuilder ,FormControl } from '@angular/forms';

@Component({
  selector: 'app-specialist-dialog-modal',
  templateUrl: './specialist-dialog-modal.component.html',
  styleUrls: ['./specialist-dialog-modal.component.scss']
})
export class SpecialistDialogModalComponent implements OnInit {

  date;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private userSrvc: UserService,
    private frmBuilder: FormBuilder
  ) {
    console.log(data);
    this.date = data;
  }


  Form: FormGroup;
  activeUser;
  id


  ngOnInit() {
    this.userSrvc.getUser().subscribe(res => {
      this.activeUser = res;
      console.log(this.activeUser);
    })


    this.Form = this.frmBuilder.group({


      MorningStartTime: new FormControl("00:00"),
      MorningEndTime: new FormControl("00:00" ),
      AfternoonStartTime: new FormControl("00:00" ),
      AfternoonEndTime: new FormControl("00:00" ),

      MorningStartTime2: new FormControl("00:00" ),
      MorningEndTime2: new FormControl("00:00" ),
      AfternoonStartTime2: new FormControl("00:00" ),
      AfternoonEndTime2: new FormControl("00:00" ),

      MorningStartTime3: new FormControl("00:00" ),
      MorningEndTime3: new FormControl("00:00" ),
      AfternoonStartTime3: new FormControl("00:00" ),
      AfternoonEndTime3: new FormControl("00:00" ),

      MorningStartTime4: new FormControl("00:00" ),
      MorningEndTime4: new FormControl("00:00" ),
      AfternoonStartTime4: new FormControl("00:00" ),
      AfternoonEndTime4: new FormControl("00:00" ),


      MorningStartTime5: new FormControl("00:00" ),
      MorningEndTime5: new FormControl("00:00" ),
      AfternoonStartTime5: new FormControl("00:00" ),
      AfternoonEndTime5: new FormControl("00:00" ),

      MorningStartTime6: new FormControl("00:00" ),
      MorningEndTime6: new FormControl("00:00" ),
      AfternoonStartTime6: new FormControl("00:00" ),
      AfternoonEndTime6: new FormControl("00:00" ),

      MorningStartTime7: new FormControl("00:00" ),
      MorningEndTime7: new FormControl("00:00" ),
      AfternoonStartTime7: new FormControl("00:00" ),
      AfternoonEndTime7: new FormControl("00:00" ),


    })
  }

  schedule() {
    this.id = this.activeUser.id;

    let timeTable = 
    {
      userId : this.id,
	    timetable :{
        monday: [
          [this.Form.value.MorningStartTime.toString(), this.Form.value.MorningEndTime.toString()], [this.Form.value.AfternoonStartTime.toString(), this.Form.value.AfternoonEndTime.toString()]
        ],
        tuesday: [
          [this.Form.value.MorningStartTime.toString(), this.Form.value.MorningEndTime2.toString()], [this.Form.value.AfternoonStartTime2.toString(), this.Form.value.AfternoonEndTime2.toString()]
        ],
        wednesday: [
          [this.Form.value.MorningStartTime3.toString(), this.Form.value.MorningEndTime3.toString()], [this.Form.value.AfternoonStartTime3.toString(), this.Form.value.AfternoonEndTime3.toString()]
        ],
        thursday: [
          [this.Form.value.MorningStartTime4.toString(), this.Form.value.MorningEndTime4.toString()], [this.Form.value.AfternoonStartTime4.toString(), this.Form.value.AfternoonEndTime4.toString()]
        ],
        friday: [
          [this.Form.value.MorningStartTime5.toString(), this.Form.value.MorningEndTime5.toString()], [this.Form.value.AfternoonStartTime5.toString(), this.Form.value.AfternoonEndTime5.toString()]
        ],
        saturday: [
          [this.Form.value.MorningStartTime6.toString(), this.Form.value.MorningEndTime6.toString()], [this.Form.value.AfternoonStartTime6.toString(), this.Form.value.AfternoonEndTime6.toString()]
        ],
        sunday: [
          [this.Form.value.MorningStartTime7.toString(), this.Form.value.MorningEndTime7.toString()], [this.Form.value.AfternoonStartTime7.toString(), this.Form.value.AfternoonEndTime7.toString()]
        ],
      }
    }

    this.userSrvc.postSchedule(this.id,timeTable).then(resp=>{
      console.log(this.id)
      console.log(resp);
      alert("submitted");
    }).catch(error=>{
      console.log(error)
    })

  }
}
