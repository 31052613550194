import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

    @ViewChild('chartRef', { static: true }) private chartRef;
    chart;

    @Input() data: any;

    constructor() {

    }

    ngOnInit() {
        //console.log(labels);

        let dataLabels = [];
        this.data.data.forEach(element => {
            dataLabels.push(element.label);
        });

        let dataValues = [];
        this.data.data.forEach(element => {
            dataValues.push(element.value);
        });

        //console.log(dataLabels, dataValues );
        


        this.chart = new Chart(this.chartRef.nativeElement, {
            type: 'horizontalBar',
            data: {
                labels: dataLabels,
                datasets: [
                    {
                        data:dataValues,
                        backgroundColor:'#02ffcc',
                        label: this.data.chartLabel,
                    },
                ]
            },

            options: {
                // labels: {
                //     // This more specific font property overrides the global property
                //     fontColor: 'black'
                // },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                responsive: true,
                plugins: {
                    labels: {
                        render: 'percentage',
                        fontSize: 14,
                        fontColor: '#ebebeb',
                    
                        showZero: true,
                    }
                },
            }


        });
    }

}
