import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ServiceTypesService {
  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public serviceTypes: BehaviorSubject<any>;
  public UsersServiceTypes: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.serviceTypes = new BehaviorSubject(null);
    this.UsersServiceTypes = new BehaviorSubject(null);

  }

  setServiceTypes(serviceTypes) {
    this.serviceTypes.next(serviceTypes);
  }

  getServiceTypes() {
    return this.serviceTypes.asObservable();
  }

  setUsersServiceTypes(UsersServiceTypes) {
    this.UsersServiceTypes.next(UsersServiceTypes);
  }

  getUsersServiceTypes() {
    return this.UsersServiceTypes.asObservable();
  }



  listAllServiceTypes() {
    return this.http.get<any>(`${this.baseUrl}lookups/serviceTypes`, {
      headers: this.headers,
    }).toPromise();
  }
}
