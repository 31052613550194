import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { OverviewComponent } from './components/admin/overview/overview.component';
import { SpecialistsComponent } from './components/admin/specialists/specialists.component';
import { AuthGuardService } from './guards/auth-guard.service';


const routes: Routes = [
  { path: 'cdd7ee98-4208-11eb-b378-0242ac130002', component: AppComponent },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuardService], children: [
      { path: 'overview', component: OverviewComponent },
      { path: 'specialists', component: SpecialistsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
