import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpecialistsService {

  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public specialists: BehaviorSubject<any>;
  public accounts: BehaviorSubject<any>;
  public base64Uri : BehaviorSubject <any>;


  constructor(private http: HttpClient) {
    this.specialists = new BehaviorSubject(null);
    this.accounts = new BehaviorSubject(0);
    this.base64Uri = new BehaviorSubject(null);
  }

  setSpecialists(specialists) {
    this.specialists.next(specialists);
  }

  setbase64Uri(uri){
    this.base64Uri.next(uri)
  }

  getSpecialists() {
    return this.specialists.asObservable();
  }

  getProfilePicture(): Observable<any> {
    return this.base64Uri.asObservable();
  }

  
  listAllSpecialists() {
    return this.http.get<any>(`${this.baseUrl}users`, {
      headers: this.headers,
      params: new HttpParams().set('role', 'SPECIALIST')
    }).toPromise();

  }

  setAccounts(accounts) {
    this.accounts.next(accounts);
  }

  getAccounts() {
    return this.accounts.asObservable();
  }

  getUploadedDocument(key: string): Promise<any> {
    return this.http.get(`${this.baseUrl}data/media`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }

  getUploadedProffessionalRegistration(key: string): Promise<any>
  {
    return this.http.get(`${this.baseUrl}professionalRegistration/getProfessionalRegistrationFile`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }

  getUploadedMembershipFile(key: string): Promise<any>{
    return this.http.get(`${this.baseUrl}membership/getMembershipFile`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }

  getUploadedQualifications(key: string): Promise<any> {
    return this.http.get(`${this.baseUrl}data/media`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }


 
  updateUser(id,user) {
    return this.http.put<any>(`${this.baseUrl}users/${id}`,user, {
      headers: this.headers,
    }).toPromise();
  
  }

  getQualifications(userId) {
    return this.http.get(`${this.baseUrl}Qualifications`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }

  sendWelcomePack(user)
  {
    return this.http.post<any>(`${this.baseUrl}data/sendWelcomePack`, user, {
      headers: this.headers,
      params: new HttpParams().set('role', 'SPECIALIST')
    }).toPromise();
  }

  sendRejectionPack(user)
  {
    return this.http.post<any>(`${this.baseUrl}data/sendRejectionEmail`, user, {
      headers: this.headers,
      params: new HttpParams().set('role', 'SPECIALIST')
    }).toPromise();
  }

  listAllAccounts() {
    return this.http.get<any>(`${this.baseUrl}users`, {
      headers: this.headers,
      params: new HttpParams().set('role', 'GENERAL')
    }).toPromise();
  }

  GetEmployement(userId)
  {
    return this.http.get(`${this.baseUrl}employments`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId).set('role', 'SPECIALIST')
    }).toPromise();
  }

  FilterSpecialistAPI(disciplineId: string, serviceTypeId: string, departmentId: string, city: string )
  {
    let params = new HttpParams();
    if (disciplineId != null || disciplineId != undefined ) {
      params = params.append('disciplineId', disciplineId);

    }
    if (serviceTypeId !=null ||serviceTypeId != undefined) {
      params = params.append('serviceTypeId', serviceTypeId);

    }
    if (departmentId !=null || departmentId != undefined) {
      params = params.append('departmentId', departmentId);

    }
    if (city !=null || city != undefined) {
      params = params.append('city', city);

    }
    params = params.append('role', 'SPECIALIST');

    return this.http.get(`${this.baseUrl}users`, {
      headers: this.headers,
      params: params
      
    }).toPromise();

  }

  getSpecialisation(industryId){
    return this.http.get(`${this.baseUrl}lookups/departments`, {
      headers: this.headers,
      params: new HttpParams().set('industryId',industryId)
    }).toPromise();
  }

 


}
