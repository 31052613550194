import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IndustriesService {
  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public disciplines: BehaviorSubject<any>;
  public UsersDisciplines: BehaviorSubject<any>;
  public cities: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
    this.disciplines = new BehaviorSubject(null);
    this.UsersDisciplines = new BehaviorSubject(null);
    this.cities = new BehaviorSubject(null);
  }

  setIndustries(disciplines){
    this.disciplines.next(disciplines);
  }

  getIndustries(){
    return this.disciplines.asObservable();
  }
  setUsersIndustries(UsersDisciplines) {
    this.UsersDisciplines.next(UsersDisciplines);
  }

  getUsersIndustries() {
    return this.UsersDisciplines.asObservable();
  }

  listAllIndustries() {
    return this.http.get<any>(`${this.baseUrl}lookups/discipline`, {
      headers: this.headers,
    }).toPromise();

  }


}
