import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { FaqService } from 'src/app/services/faq/faq.service';
import { MatDialog } from '@angular/material/dialog';
import { IndustriesDialogComponent } from 'src/app/modals/industries-dialog/industries-dialog.component';
import {  MatSnackBar, ErrorStateMatcher } from '@angular/material';


export class PersonalFormStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.touched || isSubmitted));
  }
} 
declare var $: any;
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  queryForm: FormGroup;
  mobile = false;
  isOpened = false;
  panelOpenState = false;
  questions;
  FAquestions;
  faqs = [];
  openD: boolean = false;
  faqIndex;
  loader;
  emailStatus = "Send";

  
  constructor(private formBldr: FormBuilder, private faqSrvc: FaqService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }


  industryArr;
  ngOnInit() {


    this.FAQ();
    this.GetIndustries()
    if (window.innerWidth < 680) {
      this.mobile = true;
    }
    $(window).scroll(function () {

      var wScroll = $(window).scrollTop();

      if (wScroll >= 10) {
        $('.nav-wrapper').addClass('sticky-nav');

      } else {
        $('.nav-wrapper').removeClass('sticky-nav');

      }

    });


    this.queryForm = this.formBldr.group({

      email: [
        null,
        [
          Validators.maxLength(255),
          Validators.required,
          Validators.email
        ]
      ],
      fullnames: [
        null,
        [
          Validators.minLength(2),
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z _-]*$'),
          Validators.required,
        ]
      ],
      subject: [
        null,
        [
          Validators.minLength(3),
          Validators.maxLength(255),
          Validators.required,
         
        ]
      ],
      message: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(1000),
        ]
      ],

    });


  }

  toggleMobileMenu() {

    if (this.isOpened) {
      this.isOpened = false;
    } else {
      this.isOpened = true;
    }
  }
  FAQ() {
    this.faqSrvc.getFAQ().then(resp => {
      resp.faqs.forEach(element => {
        let answer = element.answer.split('#');
        if (element.page === "landing") {
          this.faqs.push(
            {
              questions: element.question,
              answer: answer,
              list: element.list ? element.list.items : null
            }
          );
        }
      });
    })
  }


  openDialog(industry) {

    this.dialog.open(IndustriesDialogComponent, {
      data: industry,
    })


  }
  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }

  sendEmail(formDirective) {
    

    this.loader = true
    let emailObject = {
      replyTo: this.queryForm.value.email,
      name: this.queryForm.value.fullnames,
      subject: this.queryForm.value.subject,
      message: this.queryForm.value.message,
    }
    console.log("I am clicked");
    this.faqSrvc.sendEmail(emailObject).then(data => {
      formDirective.resetForm();
      this.queryForm.reset();
      console.log(data)
      this.myAlert("Email sent");
      console.log("SenD")
      this.loader = false
    }).catch(error => {
      formDirective.resetForm();
      console.log(error);
      this.loader = false
      this.queryForm.reset();
      this.myAlert("Failed, Please try again later");
    });
  }


  GetIndustries()
  {
    this.faqSrvc.getAllIndustries().then(data =>{
console.log(data)
      this.industryArr = data.disciplines;
      console.log(this.industryArr)
    }).catch(error =>{
      console.log(error)
    });
  }

  SelectedIndustry(id){
    console.log(id);
  }

  scroll(el: HTMLElement) {

    window.scrollTo({
      top: el.offsetTop - 80,
      behavior: "smooth"
    });
  }
  



}
