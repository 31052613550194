import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public cities: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
    this.cities = new BehaviorSubject(null);
  }

  setCities(cities){
    this.cities.next(cities);
  }

  getCities(){
    return this.cities.asObservable();
  }

  listAllCities() {
    return this.http.get<any>(`${this.baseUrl}lookups/cities`, {
      headers: this.headers,
    }).toPromise();
  }
}
