import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
@Component({
  selector: 'app-build-profile',
  templateUrl: './build-profile.component.html',
  styleUrls: ['./build-profile.component.scss']
})
export class BuildProfileComponent implements OnInit {

  BuildProfileForm: FormGroup;
  activeUser;
  activeUserID;
  selectedFiles;
  loader: boolean = false;

  userEntryIdentity = true;
  userEntryPassport = false;

  isSa;
  constructor(
    private formBldr: FormBuilder,
    private userSrv: UserService,
    private router: Router,
    private authSrvc: AuthService
  ) { }

  ngOnInit() {

    this.getLoggedInUser();

    this.BuildProfileForm = this.formBldr.group({

      firstname: [
        this.activeUser.firstName,
        [
          Validators.pattern('^[a-zA-Z]+$'),
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(80),
        ]
      ],
      lastname: [
        this.activeUser.lastName,
        [
          Validators.pattern('^[a-zA-Z]+$'),
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(80),
        ]
      ],
      email: [
        null,
        [
          Validators.email,
          Validators.required,

        ]
      ],
      IdNumber: [
        null,
        [
          Validators.pattern('^[0-9]+$'),
          Validators.required,
          Validators.maxLength(13),
          Validators.minLength(10)
        ]
      ],
      passportNumber: [
        null,
        [
          Validators.pattern('^[0-9]+$'),
          Validators.required,
          Validators.maxLength(13),
          Validators.minLength(10)

        ]
      ],
      city: [
        null,
        [
          Validators.required,

        ]
      ],
      contactNumber: [
        null,
        [
          Validators.pattern('^[0-9]+$'),
          Validators.required,
          Validators.maxLength(10)
        ]
      ],
      gender: [
        null,
        [
          Validators.required,

        ]
      ],
      idType: [null, [Validators.required]],
      MonthlyHours: [
       0
      ],
    });

    this.BuildProfileForm.setValue({
      firstname: this.activeUser.firstName,
      lastname: this.activeUser.lastName,
      email: this.activeUser.emailAddress
    });



  

  }


  getLoggedInUser() {
    this.userSrv.getUser().subscribe(res => {
      this.activeUser = res;
      this.populateProfileForm();
      console.log(this.activeUser);
    })


  }
  populateProfileForm() {

    if (this.activeUser.idNumber) {
      this.isSa = true;
    } else {
      this.isSa = false;
    }


    
    this.BuildProfileForm.controls.MonthlyHours.setValue(this.activeUser.monthlyHours ? this.activeUser.monthlyHours : 0);

    // Address


  }
  resetD() {
    this.BuildProfileForm.reset();

  }
  SubmitBuildProfile() {

    console.log(this.BuildProfileForm.value.IdNumber);
    console.log(this.BuildProfileForm.value.passportNumber);

    if (this.BuildProfileForm.value.IdNumber == null && this.BuildProfileForm.value.passportNumber == null) {
      //alert("Enter ID Number or Passport");
    }

    let userIdentityNumber
    if (this.BuildProfileForm.value.IdNumber != null) {
      userIdentityNumber = this.BuildProfileForm.value.IdNumber.toString();
    } else {
      userIdentityNumber = null;
    }

    let userPassport;
    if (this.BuildProfileForm.value.passportNumber != null) {
      userPassport = this.BuildProfileForm.value.passportNumber.toString();
    } else if (this.BuildProfileForm.value.passportNumber == 'null') {
      userPassport = null;
    }

    let activeUserID = this.activeUser.id;

    let expertDeatils = {
      firstname: this.BuildProfileForm.value.firstname,
      lastname: this.BuildProfileForm.value.lastname,
      gender: this.BuildProfileForm.value.gender,
      contactNumber: this.BuildProfileForm.value.contactNumber,
      IdNumber: this.BuildProfileForm.value.IdNumber,
      passportNumber: this.BuildProfileForm.value.passportNumber,
      city: this.BuildProfileForm.value.city,
      monthlyHours: 0,

    };
    console.log(expertDeatils);

    let user = {
      firstName: expertDeatils.firstname,
      lastName: expertDeatils.lastname,
      gender: expertDeatils.gender,
      role: this.activeUser.role,
      idNumber: expertDeatils.IdNumber,
      passportNumber: expertDeatils.passportNumber,
      contactNumber: expertDeatils.contactNumber,
      address: null,
      city: null,
      availability: "NOT_AVAILABLE",
      verification: false,
      industryId: null,
      status: "INACTIVE",
      idObject: null,
      slaObject: null,
      monthlyHours: 0
    }

   // let user = this.activeUser;








    this.userSrv.updateUser(activeUserID, user).then(
      res => {
        console.log(res);
       this.loader = true;
        this.router.navigate(["/specialist/dashboard/overview"]);


      }).catch(error => {
        console.log(error)
      });



  }
  radioChange() {
    let selectedOptions = this.BuildProfileForm.value.idType;
    if (selectedOptions == "Identity") {

      this.userEntryIdentity = false;
      this.userEntryPassport = true;
      this.BuildProfileForm.value.passportNumber = null
    } else if (selectedOptions == "Passport") {
      this.userEntryIdentity = true;
      this.userEntryPassport = false;
      this.BuildProfileForm.value.IdNumber = null;

    }

  }
  logOut() {
    this.authSrvc.signout().then(
      resp => {
        this.router.navigate(['/']);
      }
    )
  }
}
