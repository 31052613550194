import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-duties',
  templateUrl: './duties.component.html',
  styleUrls: ['./duties.component.scss']
})
export class DutiesComponent implements OnInit {

  emp;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private formBuilder: FormBuilder, private _snackBar: MatSnackBar
  ) {

    this.emp = data['duties'];
    console.log(this.emp);

  }

  ngOnInit() {

  }

}
