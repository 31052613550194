import { Component, OnInit } from '@angular/core';
import { UserService } from 'projects/specialist/src/app/services/user/user.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(private userSrvc : UserService) { }

  activeUser;
  ngOnInit() {
    this.userSrvc.getUser().subscribe(res => {
      this.activeUser = res;
      console.log(this.activeUser);
    })
  }

}
