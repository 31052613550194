import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public user: BehaviorSubject<any>;
  public disciplines: BehaviorSubject<any>;


  constructor(private http: HttpClient) {
    this.user = new BehaviorSubject(null);
    this.disciplines = new BehaviorSubject(null);
  }

  createUser(user) {
    return this.http.post<any>(`${this.baseUrl}users`, user, { headers: this.headers }).toPromise();
  }

  getUserByEmail(emailAddress) {
    return this.http.get<any>(`${this.baseUrl}users`, {
      headers: this.headers,
      params: new HttpParams().set('emailAddress', emailAddress)
    }).toPromise();
  }

  setUser(user) {
    this.user.next(user)
  }

  getUser() {
    return this.user.asObservable();
  }
  getAllDesciplines(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}lookups/discipline`, { headers: this.headers }).toPromise();
  }
  setDisciplines(disciplines){
    this.disciplines.next(disciplines);
  }


  getAllDepartment(disciplineId) {
    return this.http.get(`${this.baseUrl}lookups/departments`, {
      headers: this.headers,
      params: new HttpParams().set('disciplineId', disciplineId)
    }).toPromise();
  }


  getUserServiceType(userId) {
    return this.http.get<any>(`${this.baseUrl}lookups/userServiceTypes`, { headers: this.headers, params: new HttpParams().set('userId', userId) }).toPromise();
  }
}
