import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSelectChange, MatSort, MatTableDataSource } from '@angular/material';
import { SpecialistModalComponent } from '../../../modals/specialist-modal/specialist-modal.component';
import { IndustriesService } from '../../../services/industries/industries.service';
import { ServiceTypesService } from '../../../services/service-types/service-types.service';
import { SpecialistsService } from '../../../services/specialists/specialists.service';
import {CitiesService} from '../../../services/cities/cities.service';
import { UserService } from "../../../services/user/user.service";

@Component({
  selector: 'app-specialists',
  templateUrl: './specialists.component.html',
  styleUrls: ['./specialists.component.scss']
})
export class SpecialistsComponent implements OnInit {
  displayedColumns: string[] = ['names', 'email', 'serviceType', 'disciplines', 'departments', 'actions'];
  dataSource;
  specialists;
  industries = [];
  serviceTypes = [];
  stypes;
  
  usersServiceTypes;
  departments;
  disciplineId;
  serviceTypeId;
  departmentId;
  cities = [];
  SelectedCityName;
  disciplines


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private specialistsService: SpecialistsService,
    private FilterSpecialistAPI: SpecialistsService,
    public dialog: MatDialog,
    private industriesService: IndustriesService,
    private serviceTypeService: ServiceTypesService,
    private CitiesService: CitiesService,
    private userService:UserService
  ) {

    // // get industries
    // this.industriesService.getIndustries().subscribe(
    //   industries => {
    //     this.industries = industries;
    //     console.log(industries);
    //   }
    // );
    //get discplines
    this.userService.getAllDesciplines().then(
      resp => {

        this.disciplines = resp.disciplines;
        console.log(this.disciplines);
      }
    );

    //get service types
    this.serviceTypeService.getServiceTypes().subscribe(
      serviceTypes => {
        this.serviceTypes = serviceTypes;
      }
    );

    // this.CitiesService.listAllCities().then(
    //   cities => {

    //     this.cities = cities;
    //     this.cities =this.cities["city"]
    //     console.log(this.cities)
    //   }
    // );

    

    //get specialisation

    // this.specialistsService.getSpecialisation().then(resp => {
    //   this.departments = resp;
    //   this.departments = this.departments.department;
    //   console.log(resp)
    // });




    this.serviceTypeService.getUsersServiceTypes().subscribe(
      resp => {
        this.usersServiceTypes = resp;
      }
    )
  }

  ngOnInit() {
    this.specialistsService.getSpecialists().subscribe(
      resp => {
       // this.specialists = this.appendServiceType(resp);
        this.dataSource = new MatTableDataSource(this.formatUsers(resp));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(this.dataSource);
        
      }
    )
  }

  joinToString(arr) {
    if (!Array.isArray(arr)) {
      console.log("no");

      return null
    } else {
      let tempArr = [];
      arr.forEach(element => {
        tempArr.push(element.name);
      });
      tempArr.sort();

      return tempArr.join(', ')
    }



  }

  /*appendServiceType(users) {

    for (let index = 0; index < users.length; index++) {
      users[index].serviceType = [];
      this.usersServiceTypes.forEach(element => {

        if (users[index].id === element.userId) {
          users[index].serviceType.push(element.serviceType);
        }
      });

    }

    console.log(users);


    return users
  }*/

  formatUsers(specialists) {
    let users = [];


    specialists.forEach(element => {

      users.push({
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.emailAddress,
        // city: element.city,
        availability: element.availability,
        disciplines: this.joinToString(element.disciplines),
        departments: this.joinToString(element.departments),
        serviceType: this.joinToString(element.serviceTypes),
        date: element.createdAt,
        specialistObj: element
      })
    });

    return users;
  }

  applyFilter(event: Event) {


    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();


  }

  selectFilter(event: Event) {
    let filterValue = event['value'];

    if (Array.isArray(filterValue)) {
      filterValue = filterValue.join(', ');
      console.log(filterValue);

    }

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  SelectedValue(e) {
    console.log(e);
  }


  //getting the selected industry
  SelectedDiscipline(event: MatSelectChange) {


    let selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
    console.log(selectedData.value);

    this.disciplineId = selectedData.value;

    this.specialistsService.getSpecialisation(this.disciplineId).then(res => {
      console.log(res)
      this.departments = res;
      this.departments = this.departments.department;
    })

  }
  SelectedDepartment(event: MatSelectChange) {


    let selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
    console.log(selectedData.value);

    this.departmentId = selectedData.value;

  }
  SelectedServiceType(event: MatSelectChange) {


    let selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
    console.log(selectedData.value);

    this.serviceTypeId = selectedData.value;

  }
  // SelectedCity(event: MatSelectChange) {


  //   let selectedData = {
  //     value: event.value,
  //     text: event.source.triggerValue
  //   };
  //   console.log(selectedData.value);

  //   this.SelectedCityName = selectedData.value;

  // }
  viewUser(user) {
    console.log(user);
    const dialogRef = this.dialog.open(SpecialistModalComponent, {
      width: '900px',
      height: '780px',
      data: user
    });

  }

  filterByDate(event: Event) {

    let filterValue = event['value']
    if (filterValue == '') {
      this.dataSource = new MatTableDataSource(this.formatUsers(this.specialists));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log('reset');

      return
    }

    filterValue = parseInt(event['value']);
    let filteredArr = [];

    this.specialists.forEach(element => {
      if (this.differenceInDays(element.createdAt) <= filterValue) {
        console.log('match');

        filteredArr.push(
          element
        )
      }
    });
    console.log(filteredArr);

    this.dataSource = new MatTableDataSource(this.formatUsers(filteredArr));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  differenceInDays(then) {
    let difference = new Date().getTime() - then
    return (((difference / 1000) / 60) / 60 / 24)
  }

  filterSpecialists() {

    this.specialistsService.FilterSpecialistAPI(this.disciplineId, this.serviceTypeId, this.departmentId, this.SelectedCityName).then(
      resp => {
        // this.specialists = this.appendServiceType(resp);
        let myspecialists = [];
         myspecialists = resp["users"];
        this.dataSource = new MatTableDataSource(this.formatUsers(myspecialists));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(myspecialists)

      }
    )
    console.log(this.disciplineId)
    console.log(this.departmentId)
    console.log(this.serviceTypeId)
  }

}