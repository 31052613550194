import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../user/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loader: BehaviorSubject<boolean>;
  public isAuthicated : BehaviorSubject<any>;

  constructor(
    private userService : UserService
  ) {
    this.isAuthicated = new BehaviorSubject(false);
    //this.authState();
  }

  getAuthState(): Observable<any> {
    return this.isAuthicated.asObservable();
  }

  setAuthState(value){
    this.isAuthicated.next(value);
  }

  authState() {

    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          let email = user['email'];
          if (user['emailVerified'] == false) {
            this.isAuthicated.next(false);
            reject(null);
            return;
          }
          this.isAuthicated.next(true);
          this.userService.getUserByEmail(email).then(
            resp => {
              if(resp.users.length > 0){
                this.userService.setUser(resp.users[0]);
                resolve(resp.users[0]);
              }else{
                this.isAuthicated.next(false);
                reject(null)
              }
             
            }
          )
        } else {
          this.isAuthicated.next(false);
          reject(null);
        }
      });
    })
  }

  resetPassword(emailAddress) {

    return new Promise((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(emailAddress).then(function () {
        resolve(true)
      }).catch(function (error) {
        reject(false)
      });
    });

  }

  sendEmailVerification() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        user.sendEmailVerification().then(
          resp => {
            resolve(true)
          }
        ).catch(
          error => {
            reject(false)
          }
        )
      })
    });

  }

 
  userSignin(email, password) {

    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(
        resp => {
          //console.log(resp);
          this.isAuthicated.next(true);
          resolve(resp);
        }
      ).catch(error => {
        //console.log(error);
        reject(error);

      });
    });


  }

  userSignup(email, password) {

    return new Promise((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email,password).then(
        result => {
        console.log(result);        
        resolve({ user: result.user })
      }, error => {
        //console.log(error);
        reject(error);
      }).catch(error => {
        //console.log(error);
        reject(error)

      });

    });

  }

  signout() {

    return new Promise((resolve, reject) => {
      firebase.auth().signOut().then(resp => {
        this.isAuthicated.next(false);
        resolve(resp);
      }).catch(error => {
        reject(error)
      });
    });

  }

}
