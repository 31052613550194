import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-specialist',
  templateUrl: './specialist.component.html',
  styleUrls: ['./specialist.component.scss']
})
export class SpecialistComponent implements OnInit {
  loading = true;
  user;
  id;
  constructor(
    private userService : UserService,
    private authService : AuthService,
    private router : Router
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(
      resp =>{
       
        this.user = resp;
        if(this.user != null){
          console.log('this user logged in');          
          console.log(resp);
          if(this.user.role === "GENERAL"){
            this.router.navigate(['/specialist/playroom']);
          }
          this.loading = false;
        }
        
      }
    )

    }
  signOut() {
   
    if(confirm("Are sure you want to sign out?")){
      this.authService.signout().then(
        resp => {
          this.router.navigate(['/specialist-signup']);
        }
      );
    }
  }

}
