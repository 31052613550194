import { Component, OnInit } from '@angular/core';
declare var $: any;
import { UserService } from 'projects/specialist/src/app/services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ProfilePictureComponent } from '../../../modal/profile-picture/profile-picture.component';
import {UpdateProfileComponent} from '../../../modal/update-profile/update-profile.component'


export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading = true;
  activeUser;
  accountStatus;
  base64Uri;
  loader;
  rateTimeout;

  constructor(
    private userSrvc: UserService,
    private authSrvc: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) { }
  
  ngOnDestroy() {
    clearTimeout(this.rateTimeout);
  }

  ngOnInit() {
    this.userSrvc.getUser().subscribe(res => {
      this.activeUser = res;
      this.accountStatus = this.activeUser.status;
      console.log(this.activeUser);
      this.loading = false;
    });

    this.userSrvc.getbase64Uri().subscribe(
      resp => {
        this.profilePicture.preview = resp
      }
    );

    this.userSrvc.getbase64Uri().subscribe(
      resp =>{
       
        this.base64Uri = resp
      }
    );

    if(!this.base64Uri &&  this.activeUser.profilePicture != null){
      this.userSrvc.getUploadedProfilePicture(this.activeUser.profilePicture.key).then(
        resp => {
          this.base64Uri = 'data:image/jpeg;base64,' + resp;
          this.userSrvc.setbase64Uri('data:image/jpeg;base64,' + resp)
        }
      )
    }
  }

  ngAfterViewInit() {
    $('.option').on('click', function () {
      $('.option').removeClass('active-link');
      $(this).addClass('active-link');
    });
   

    this.rateTimeout = setTimeout(()=>{
      if(this.activeUser.idNumber === null || this.activeUser.slaObject === null || this.activeUser.idObject === null || this.activeUser.sectors.length === 0 
        || this.activeUser.address === null || this.activeUser.departments.length === 0 
        || this.activeUser.disciplines.length === 0 || this.activeUser.skills.length === 0 || this.activeUser.focuses.length === 0 || this.activeUser.biography === null|| 
        !this.activeUser.consents[1])
      {
        this.dialog.open(UpdateProfileComponent, {
          disableClose: true,
          panelClass: 'custom-dialog-container'
        })
      }
    }, 3500);
  }
  
  showLoader() {

    this.loader = true
    setInterval(() => {
      this.loader = false
    }, 2000)

  }
  
  logOut() {
    this.authSrvc.signout().then(
      resp => {
        this.router.navigate(['/']);
      }
    )
  }

  profilePicture = {
    preview: null,
    file: null,
    extension: null,
    fileName: null
  };

  // profileUpload(event) {

  //   let file = event.target.files[0];
  //   let filename = file.name;
  //   let extension = filename.split('.');
  //   extension = extension[extension.length - 1];

  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.profilePicture = {
  //         preview: event.target.result,
  //         file: file,
  //         extension: extension,
  //         fileName: filename
  //       };

  //     }
  //     reader.readAsDataURL(event.target.files[0]);

  //     this.uploadProfilePicture();
  //   }


  //    if (this.profilePicture.file) {
  //     this.uploadProfilePicture().then(
  //       profileObj => {
  //         console.log(profileObj);
  //         this.activeUser.profilePicture = profileObj;

  //         this.userSrvc.getUploadedProfilePicture(this.activeUser.profilePicture.key).then(
  //           resp => {
  //             this.profilePicture.preview = 'data:image/jpeg;base64,' + resp;
  //             this.userSrvc.setbase64Uri('data:image/jpeg;base64,' + resp)
  //           }
  //         )

  //         let id = this.activeUser.id;
          

  //         this.userSrvc.updateUser(id, this.activeUser).then(resp => {
  //           console.log(resp);
  //           this.userSrvc.setUser(resp);
  //           this.loading = false
      
  //         }).catch(error => {
  //           console.log(error);
  //           this.loading = false
  //         });
  //         this.profilePicture.file = null

  //       }
  //     )

  //     return
  //   }

  //   console.log(this.profilePicture);
   

  // }

  //  // Upload Profile Picture
  //  uploadProfilePicture(){

  //   let dataObject;
  //   var formData: any = new FormData();

  //   return new Promise((resolve, reject) => {

  //     console.log(this.profilePicture.extension);

  //     this.userSrvc.profilePictureUrl(this.profilePicture.extension).then(dataObject => {

  //       let fileKey = dataObject.id;
  //       Object.keys(dataObject.presignedPost.fields).forEach(key => {
  //         formData.append(key, dataObject.presignedPost.fields[key])
  //       });

  //       formData.append('file', this.profilePicture.file);

  //       this.userSrvc.uploadProfilePicture(dataObject.presignedPost.url, formData).then(data => {

  //         let profileObj = {
  //           key: formData.get('key'),
  //           fileName: this.profilePicture.fileName,
  //           extension: this.profilePicture.extension
  //         };

  //         resolve(profileObj);

  //       });

  //     }).catch(
  //       error => {
  //         console.log(error);

  //       }
  //     )
  //   })
  // }
 

  openDialog(): void {
   const dialogRef = this.dialog.open(ProfilePictureComponent, {
      height: '500px',
      width: '400px',
    
    });

  }

 
}
