import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user/user.service';
import { ProffessionalRegistrationComponent } from '../../../../modal/proffessional-registration/proffessional-registration.component';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { MembershipOfAssociationComponent } from '../../../../modal/membership-of-association/membership-of-association.component';
import { ProjectCompletedComponent } from '../../../../modal/project-completed/project-completed.component';
import { DutiesComponent } from '../../../../modal/duties/duties.component';
import {SpecialistsService} from '../../../../../../../admin/src/app/services/specialists/specialists.service'

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})




export class ProfileComponent implements OnInit {
  activeUser;

  loading = false;
  workExperince = [];
  qualifications = [];
  serviceTypes = [];
  departments = [];
  industry = {};
  disciplines = [];
  skills = [];
  sectors = [];
  focuses = [];


  panelOpenState;

  newUser;
  accountStatus;
  role;
  currentUser;
  employment
 
  constructor(private userSrv: UserService, public dialog: MatDialog, private _snackBar: MatSnackBar , private specialistService: SpecialistsService) { }
  base64Uri;
  profile = false;
  profilePicture;
  
  displayedColumns: string[] = ['col1', 'col2', 'col3', "col4", "col5"];  
 
  faqIndex = -1;
  displayedColumnsQualification: string[] = ['level', 'course_name', 'institution', 'qualificationFile', "action"];
  displayedColumnsEmp: string[] = ['position', 'company', 'duration', 'numberOfYears', "responsilibities", "action"];
  userProfessionalRegistration = [];
  userMembership = [];
  userProjectsCompleted = [];
  dataSource
  dataSourceMembership
  dataSourceProjectsComplted
  dataSourceEmp;
  dataSourcequalifications;
  ngOnInit() {

    this.userSrv.getUser().subscribe(resp => {
      this.activeUser = resp;
      this.profile = true;
      this.accountStatus = this.activeUser.status;
      this.role = this.activeUser.role;
      this.serviceTypes = this.activeUser.serviceTypes ? this.activeUser.serviceTypes : [];
      this.departments = this.activeUser.departments ? this.activeUser.departments : [];
      this.industry = this.activeUser.industry ? this.activeUser.industry : this.activeUser.industry;
      this.disciplines = this.activeUser.disciplines ? this.activeUser.disciplines : this.activeUser.disciplines
      this.skills = this.activeUser.skills ? this.activeUser.skills : this.activeUser.skills
      this.sectors = this.activeUser.sectors ? this.activeUser.sectors : this.activeUser.sectors
      this.focuses = this.activeUser.focuses ? this.activeUser.focuses : this.activeUser.focuses
      console.log(this.serviceTypes)

      console.log(this.sectors);      
      console.log(this.focuses)

      console.log(this.focuses);

      this.GetUserWorkExperience(this.activeUser.id);
      this.getUserQualifications(this.activeUser.id);
      this.getprofessionalRegistrationByUser(this.activeUser.id);
      this.getMembershipsByUser(this.activeUser.id);
      this.getProjectsCompletedByUser(this.activeUser.id);
    })



    if (!this.base64Uri && this.activeUser.profilePicture != null) {
      this.userSrv.getUploadedProfilePicture(this.activeUser.profilePicture.key).then(
        resp => {
          this.base64Uri = 'data:image/jpeg;base64,' + resp;
          this.userSrv.setbase64Uri('data:image/jpeg;base64,' + resp);
          //this.profilePicture.preview = this.base64Uri;
        }
      )
    }

  }

  getprofessionalRegistrationByUser(id) {
    this.userSrv.getprofessionalRegistrationByUser(id).then(result => {
      this.userProfessionalRegistration = result['professionalRegistration'];
      this.dataSource = new MatTableDataSource(this.userProfessionalRegistration);

    })
  }

  getMembershipsByUser(id) {
    this.userSrv.getMembershipsByUser(id).then(result => {
      this.userMembership = result['memberships'];
      this.dataSourceMembership = new MatTableDataSource(this.userMembership);

    })
  }
  getProjectsCompletedByUser(id) {
    this.userSrv.getProjectsCompletedByUser(id).then(result => {
      this.userProjectsCompleted = result['projectsCompleted'];
      this.dataSourceProjectsComplted = new MatTableDataSource(this.userProjectsCompleted);

    })
  }
  GetUserWorkExperience(id) {
    this.userSrv.getWorkExperiences(id).then(result => {
      this.workExperince = result['employments'];
      console.log(this.workExperince)
      this.dataSourceEmp = new MatTableDataSource(this.workExperince);
    })
  }
  removeEmployment(element) {
    this.loading = true;
    //console.log(this.selectedEmployments);
    this.userSrv.deleteWorkExperience(element.id).then(resp => {
      this.loading = false;
      this.GetUserWorkExperience(this.activeUser.id);
      this.myAlert("Employment deleted Successfully");
    }).catch(error => {
      this.myAlert("Item could not be removed, please try again later!");
    })
  }
  removeQualification(element) {
    this.loading = true;
    this.userSrv.deleteQualification(element.id).then(resp => {
      this.myAlert("Qualification deleted Successfully");
    }).catch(error => {
      this.myAlert("Item could not be removed, please try again later!");
    })
  }
  getUserQualifications(id) {
    this.userSrv.getQualifications(id).then(resp => {
      this.qualifications = resp['qualifications'];
      console.log(this.qualifications)
      this.dataSourcequalifications = new MatTableDataSource(this.qualifications);
    })
  }


  deleteProfessionalRegistration(element) {
    this.loading = true;
    this.userSrv.deleteprofessionalRegistration(element.id).then(resp => {
      this.getprofessionalRegistrationByUser(this.activeUser.id)
      this.loading = false;
      this.myAlert("Professional Registration deleted Successfully");
    }).catch(error => {
      this.myAlert("Item could not be removed, please try again later!");
    })
  }
  deleteMembership(element) {
    this.loading = true;
    this.userSrv.deleteMembership(element.id).then(resp => {
      this.loading = false;
      this.getMembershipsByUser(this.activeUser.id)
      this.myAlert("Membership of association deleted Successfully");
    }).catch(error => {
      this.myAlert("Item could not be removed, please try again later!");
    })
  }
  deleteProjectsCompleted(element) {
    this.loading = true;
    this.userSrv.deleteProjectCompleted(element.id).then(resp => {
      this.loading = false;
      this.getProjectsCompletedByUser(this.activeUser.id);
      this.myAlert("Project completed deleted Successfully");
    }).catch(error => {
      this.myAlert("Item could not be removed, please try again later!");
    })
  }


  openProffessionalRegistrationDialog(): void {
    const dialogRef = this.dialog.open(ProffessionalRegistrationComponent, {
      height: '500px',
      width: '600px',

    });

  }

  openMemmbershipOfAssociationDialog() {
    const dialogRef = this.dialog.open(MembershipOfAssociationComponent, {
      height: '500px',
      width: '600px',

    });
  }

  openProjectsCompletedDialog() {
    const dialogRef = this.dialog.open(ProjectCompletedComponent, {
      height: '500px',
      width: '600px',
    });
  }

  ViewEmploment(emp) {
    console.log(emp);
    const dialogRef = this.dialog.open(DutiesComponent, {
      width: '700px',
      height: '580px',
      data: emp
    });
  }

  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }
  tiles: Tile[] = [
    {text: 'One', cols: 2, rows: 1, color: '#F5F8F9'},
    {text: 'Two', cols: 2, rows: 1, color: '#F5F8F9'},
    {text: 'Three', cols: 2, rows: 2, color: '#F5F8F9'},
    {text: 'Four', cols: 2, rows: 2, color: '#F5F8F9'},
  ];



  downloading = false;
  download(file) {
    console.log(file);
    this.downloading = true;
    this.specialistService.getUploadedDocument(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }
  

  downloadMembershipFile(file)
  {
    file = file.certificate;
  
    this.downloading = true;
    this.specialistService.getUploadedMembershipFile(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    }) 
  }

  downloadQualifications(file) {

    file = file.qualificationFile;
    console.log(file);
    this.downloading = true;
    this.specialistService.getUploadedQualifications(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }

  downloadProffessionalFile(file)
  {
    file = file.professionalFile;
    console.log(file);
    
    console.log(file);
    this.downloading = true;
    this.specialistService.getUploadedProffessionalRegistration(file.key).then(resp => {

      if (file.extension == "pdf") {
        this.previewOnTab(resp)
      }

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    }) 
  }

  previewOnTab(data) {
    var type = 'application/pdf';
    let blob = null;
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, type));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
    this.downloading = false;
  }

  pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  expand(i) {

    if (i == this.faqIndex) {
      this.faqIndex = -1;
    } else {
      this.faqIndex = i;
    }

  }


}
