import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { IndustriesService } from '../../services/industries/industries.service';
import { ServiceTypesService } from '../../services/service-types/service-types.service';
import { SpecialistsService } from '../../services/specialists/specialists.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  opened = true;
  hasBackdrop = false;
  mode = "side";
  page = "overview";
  activeUser;
  loading = true;

  @ViewChild("drawer", { static: true }) drawer: ElementRef;

  constructor(
    private userService : UserService,
    private specialistsService : SpecialistsService,
    private authService : AuthService,
    private router : Router,
    private industriesService : IndustriesService,
    private serviceTypeService : ServiceTypesService,
  ) { 
    this.userService.getUser().subscribe(
      resp=>{this.activeUser=resp;
      }
    );

   

   

    this.specialistsService.listAllSpecialists().then(
      resp =>{
        this.specialistsService.setSpecialists(resp.users);

        this.specialistsService.listAllAccounts().then(
          resp =>{
            this.specialistsService.setAccounts(resp.users)
            this.getIndustries();
          
          }
        )
      }
    );
  }

  getIndustries(){
    this.industriesService.listAllIndustries().then(
      resp =>{
        console.log(resp);
        this.industriesService.setIndustries(resp.disciplines)
        this.getServiceTypes();
      }
    )

  }

  getServiceTypes(){
    this.serviceTypeService.listAllServiceTypes().then(
      resp =>{
        //console.log(resp);
        this.serviceTypeService.setServiceTypes(resp.industries)
        this.loading = false;
      }
    )

  }

  ngOnInit() {
    let temp = this.router.url.split("/");
    this.page = temp[2];
  }

  changepage(param) {
    this.page = param;

  }

  signOut() {

    let confirm  = window.confirm('Are you sure you want to sign out?');
    if (confirm) {
      this.authService.signout().then(
        resp => {
          this.router.navigate(['/cdd7ee98-4208-11eb-b378-0242ac130002']);
        }
      );
    }
  }

}
