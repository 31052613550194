import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/specialist/dashboard/dashboard.component';
import { EditComponent } from './components/specialist/dashboard/edit/edit.component';
import { OverviewComponent } from './components/specialist/dashboard/overview/overview.component';
import { ProfileComponent } from './components/specialist/dashboard/profile/profile.component';
import { ScheduleComponent } from './components/specialist/dashboard/schedule/schedule.component';
import { PlayroomComponent } from './components/specialist/playroom/playroom.component';
import { SpecialistComponent } from './components/specialist/specialist.component';
import { EditEmploymentComponent } from './components/specialist/employment/edit-employment/edit-employment.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { TermsComponent } from './components/terms and conditions/terms/terms.component';
import { BuildProfileComponent } from './components/specialist/build-profile/build-profile.component';
import { ProfilePictureComponent } from './modal/profile-picture/profile-picture.component';
import {UpdateProfileComponent} from './modal/update-profile/update-profile.component';
import {ResetpasswordComponent} from './modal/resetpassword/resetpassword.component'
import {ProffessionalRegistrationComponent} from './modal/proffessional-registration/proffessional-registration.component'
import { MembershipOfAssociationComponent } from './modal/membership-of-association/membership-of-association.component';
import {ProjectCompletedComponent} from './modal/project-completed/project-completed.component'
import {DutiesComponent} from './modal/duties/duties.component';


const routes: Routes = [
  { path: 'specialist-landing', component: AppComponent },
  { path: 'terms', component: TermsComponent },
  

  {
    path: 'specialist', component: SpecialistComponent, canActivate: [AuthGuardService], children: [
      // { path: 'playroom', component: PlayroomComponent },
      // { path: 'create-profile', component: BuildProfileComponent },
      //  { path: 'edit', component: EditComponent },
      // { path: 'profile', component: ProfileComponent },
      {
        path: 'dashboard', component: DashboardComponent, children: [
          { path: '', component: OverviewComponent },
          { path: 'profile', component: ProfileComponent },
          { path: 'edit', component: EditComponent },
          { path: 'Employment-duties', component: DutiesComponent },
          { path: 'projects-completed', component: ProjectCompletedComponent },
          { path: 'editEmployment', component: EditEmploymentComponent },
          { path: 'profile', component: ProfilePictureComponent },
          { path: 'reset-password', component: ResetpasswordComponent },
          { path: 'incompplete-profile', component: UpdateProfileComponent },
          { path: 'proffessional-registration', component: ProffessionalRegistrationComponent },
          { path: 'membership-association', component: MembershipOfAssociationComponent },

        ]
      },
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
