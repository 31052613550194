import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  mode = true;
  consented = false;
  resetPassword: FormGroup;
  error = {
    message: null,
    state: false
  };

  resp = {
    message: '',
    state: false
  };

  loading = false;

  timeOut;
  email;
  password;


  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.resetPassword = this.formBuilder.group({
      emailAddress: [
        null, [
          Validators.email,
          Validators.required,
        ]
      ],
    });

   
  }

  toggle() {
    this.error.state = false;
    this.resp.state = false;
    clearTimeout(this.timeOut);
    this.mode = !this.mode
  }

  reset(formDirective){
    this.loading = true;
    let email = this.resetPassword.value.emailAddress;
    this.authService.resetPassword(email).then(
      resp =>{
        this.toggle();
        this.resetPassword.reset();
        formDirective.resetForm();
        this.resp.message = "A link to reset your password has been sent to " + email;
        this.resp.state = true;
        this.loading = false;
      }
     
    ).catch(
      error =>{
        console.log(error);
        this.formAlert(this.authValidator(error))
        this.loading = false;
      }
    )
  }

  formAlert(message) {
    this.error.message = message;
    this.error.state = true;
    this.timeOut = setTimeout(() => {
      this.error.state = false;
      clearTimeout(this.timeOut);
    }, 6000);

  }

  signIn() {
    this.resp.state = false;
    this.loading = true;
    this.authService.userSignin(this.email, this.password).then(
      firebaseUser => {
        let user = firebaseUser['user'];
        if (user['emailVerified'] == false) {
          this.resp.message = 'A link to verify your email has been sent to ' + user['email'] + '. verify your email before you can login.';
          this.email = null;
          this.password = null;
          this.resp.state = true;
          this.loading = false;
          return;
        }

        this.userService.getUserByEmail(this.email).then(
          resp => {
        
            if (resp.users.length > 0) {
              if(resp.users[0].role == 'ADMIN'){
                this.userService.setUser(resp.users[0]);
                this.authService.setAuthState(true);
                this.loading = false;
                this.router.navigate(['/admin/overview']);
              }else{
                this.loading = false;
                this.formAlert('You are not authorized to access this portal.');
              }
             
            } else {
              this.loading = false;
              this.formAlert('There was an issue feaching your profile, please try again later.');
            }
          }
        ).catch(
          error => {
            console.log(error);
            
            this.loading = false;
            this.formAlert('There was an issue feaching your profile, please try again later.')
          }
        )

      },
    ).catch(
      error => {
        this.loading = false;
        console.log(error);
        
        let errorMessage = this.authValidator(error);
        this.formAlert(errorMessage);
      }
    )
  }

  authValidator(error) {
    switch (error['code']) {
      case "auth/wrong-password":
        return "Invalid email address or password.";
        break;
      case "auth/invalid-email":
        return "Please enter a valid email address.";
        break;
      case "auth/user-not-found":
        return "Please create an account first.";
        break;
      case "auth/weak-password":
        return "Password should be at least 6 characters.";
        break;
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
        break;
      case "auth/uid-already-exists":
        return "The email address is already in use by another account.";
        break;
      case "auth/network-request-failed":
        return "Please make sure you are connected to the internet";
        break;
      case "auth/argument-error":
        return "Please fill your creditials";
        break;
      default:
        return "Something went wrong, please try again later";

    }
  }

}
