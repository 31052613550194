import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialistDialogModalComponent } from '../../../../modal/specialist-dialog-modal/specialist-dialog-modal.component';


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})

export class ScheduleComponent implements OnInit {
  monday;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
  /*openDialog(date) {
    
    this.dialog.open(SpecialistDialogModalComponent, {
      data : date
    });*/


  openDialog() {

    this.dialog.open(SpecialistDialogModalComponent)
  }

}