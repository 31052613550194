import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service'
import { MatSnackBar, ErrorStateMatcher } from '@angular/material';

@Component({
  selector: 'app-project-completed',
  templateUrl: './project-completed.component.html',
  styleUrls: ['./project-completed.component.scss']
})
export class ProjectCompletedComponent implements OnInit {
  projectsCompletedForm: FormGroup;
  constructor(private frmBldr: FormBuilder, private usrSrv: UserService,  private _snackBar: MatSnackBar, public dialog: MatDialog) { }
  activerUser;
  load = false;

  ngOnInit() {
    this.usrSrv.getUser().subscribe(resp => {
      this.activerUser = resp;
      console.log(resp)
    })

    this.projectsCompletedForm = this.frmBldr.group({
      position: [null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(2)

      ]],
      projectName: [null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(2)

      ]],
      projectLink:[null, [
        Validators.maxLength(255),
        Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
        Validators.minLength(2)

      ]],
      projectDescription: [null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(2)

      ]]
    })
  }

  submitProjectsDetails()
  {
    this.load = true;

    let projectsCompleted = {
      userId: this.activerUser.id,
      projectName: this.projectsCompletedForm.value.projectName,
      link: this.projectsCompletedForm.value.projectLink,
      position: this.projectsCompletedForm.value.position,
      projectDescription: this.projectsCompletedForm.value.projectDescription
    };

    console.log(projectsCompleted);

    this.usrSrv.createProjectsCompleted(projectsCompleted).then(data =>{
      this.load = false;
      console.log(data);
      this.myAlert("Professional Registraion Submited success updated successfully");
          this.closeDialog()
    }).catch(
      error => {
        console.log(error);

      }
    );
    
  }
  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }
  // // openProffessionalRegistrationDialog(): void {
  //   let dialogReff = this.dialog.open(ProffessionalRegistrationComponent, {
  //      height: '500px',
  //      width: '600px',
     
  //    });
 
  // //  }
  closeDialog(){
    this.dialog.closeAll()
  }}
