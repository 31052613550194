import { Injectable } from '@angular/core';
import {HttpClient , HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs'; 
import { FormControl } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class FaqService {

  private baseUrl = environment.baseUrl;
  private headers = new HttpHeaders({"Authorization":"f322c39f-79e7-4013-9a79-9f17507f57a0"})
  public activeUser: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
   }
   getFAQ(): Promise<any>{
      return this.http.get<any>(`${this.baseUrl}faqs`, {headers: this.headers}).toPromise();
  }

  sendEmail(emailData)
  {
    return this.http.post<any>(`${this.baseUrl}data/contactUsEmailSender`, emailData, {headers: this.headers}).toPromise();
  }

  getAllIndustries()
  {
    return this.http.get<any>(`${this.baseUrl}lookups/discipline`, {headers: this.headers}).toPromise();
  }
  nameValidator(control: FormControl): { [s: string]: boolean } {
    const nameRegex = /^[a-zA-Z _-]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (!nameRegex.test(control.value) && control.value) {
      return { nameValidator: true };
    }
    return null;
  }
}
