import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.baseUrl;

  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });
  public user: BehaviorSubject<any>;
  public base64: BehaviorSubject<any>;
  public skills: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.user = new BehaviorSubject(null);
    this.base64 = new BehaviorSubject(null);
    this.skills = new BehaviorSubject(null);

    this.GetAllSkills().then(
      resp => {
        console.log(resp);
        let skills = resp['skills'];
        let tempArr = []
        skills.forEach(element => {
          tempArr.push(
            element.skill
          )
        });
        
        this.skills.next(tempArr);
      }
    )
  }

  getSkillsObservable() {
    return this.skills.asObservable()
  }

  createUser(user) {
    return this.http.post<any>(`${this.baseUrl}users`, user, { headers: this.headers }).toPromise();
  }

  getUserByEmail(emailAddress) {
    return this.http.get<any>(`${this.baseUrl}users`, {
      headers: this.headers,
      params: new HttpParams().set('emailAddress', emailAddress)
    }).toPromise();
  }
  updateUser(id, user) {
    return this.http.put<any>(`${this.baseUrl}users/${id}`, user, {
      headers: this.headers,
    }).toPromise();

  }
  updateUserWorkExperience(selectedEmployments) {
    let query = [];
    selectedEmployments.forEach(element => {
      query.push(
        this.http.post<any>(`${this.baseUrl}employments`, element, {
          headers: this.headers,
        }).toPromise()
      )
    });
    return forkJoin(query).toPromise();

  }
  updateEmployment(id, employment) {
    return this.http.put<any>(`${this.baseUrl}employments/${id}`, employment, {
      headers: this.headers,
    }).toPromise();

  }

  updateQualifications(id, qualifications) {
    return this.http.put<any>(`${this.baseUrl}Qualifications/${id}`, qualifications, {
      headers: this.headers,
    }).toPromise();

  }

  updateUserQualifications(qualifications) {

    let query = [];
    qualifications.forEach(element => {
      query.push(
        this.http.post<any>(`${this.baseUrl}Qualifications`, element, {
          headers: this.headers,
        }).toPromise()
      )
    });

    return forkJoin(query).toPromise();

  }

  //get the presigned url 

  Getpresignedurl(filesToUpload): Promise<any> {
    let query = [
      this.http.post(`${this.baseUrl}data/media`, { fileExtension: filesToUpload.sla.extension, folder: "cv" }, { headers: this.headers }),
      this.http.post(`${this.baseUrl}data/media`, { fileExtension: filesToUpload.id.extension, folder: "id-document" }, { headers: this.headers }),
    ];

    return forkJoin(query).toPromise();

  }

  uploadFile(url, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    let query = [
      this.http.post(url[0].presignedPost.url, formdata.sla, { headers: header }),
      this.http.post(url[1].presignedPost.url, formdata.id, { headers: header }),
    ];

    return forkJoin(query).toPromise();

  }


  GetDocumentsPresignedUrl(fileExtension): Promise<any> {
    let query = [];
    console.log(fileExtension);

    fileExtension.forEach(element => {
      query.push(
        this.http.post(`${this.baseUrl}data/media`, { fileExtension: element.fileObj.fileExtension, folder: "personal-documents" }, { headers: this.headers }).toPromise()
      )
    });

    return forkJoin(query).toPromise();
  }


  GetQualificationsPresignedUrl(fileExtension): Promise<any> {
    let query = [];
    console.log(fileExtension);

    fileExtension.forEach(element => {
      query.push(
        this.http.post(`${this.baseUrl}data/media`, { fileExtension: element.fileObj.fileExtension, folder: "qualifications" }, { headers: this.headers }).toPromise()
      )
    });

    return forkJoin(query).toPromise();

  }

  uploadQualificationFile(formsDataArr): Promise<any> {
    console.log(formsDataArr);

    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    let query = [];
    formsDataArr.forEach(formData => {
      query.push(
        this.http.post(formData.url, formData.data, { headers: header }).toPromise()
      );
    });

    return forkJoin(query).toPromise();
  }

  getWorkExperiences(userId) {
    return this.http.get(`${this.baseUrl}employments`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }

  getWorkExperience(id) {
    return this.http.get(`${this.baseUrl}employments/${id}`, {
      headers: this.headers
    }).toPromise();
  }

  deleteWorkExperience(id) {
    return this.http.delete(`${this.baseUrl}employments/${id}`, {
      headers: this.headers,
    }).toPromise();
  }

  deleteQualification(id) {
    return this.http.delete(`${this.baseUrl}Qualifications/${id}`, {
      headers: this.headers,
    }).toPromise();
  }
  // Get qualification for one user
  getQualifications(userId) {
    return this.http.get(`${this.baseUrl}Qualifications`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }
  //get user service types





  setUser(user) {
    this.user.next(user)
  }

  getUser() {
    return this.user.asObservable();
  }
  postSchedule(id, time) {
    return this.http.put<any>(`${this.baseUrl}timetables/${id}`, time, {
      headers: this.headers,
    }).toPromise();

  }

 

  profilePictureUrl(fileExtension: string): Promise<any> {
    return this.http.post(`${this.baseUrl}data/media`, { fileExtension: fileExtension, folder: "profilePictures" }, { headers: this.headers }).toPromise();
  }

  uploadProfilePicture(url: string, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formdata, { headers: header }).toPromise();
  }

  getUploadedProfilePicture(key): Promise<any> {
    return this.http.get(`${this.baseUrl}data/media/`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }

  setbase64Uri(base64) {
    this.base64.next(base64);
  }

  getbase64Uri() {
    return this.base64.asObservable();
  }

  validateID(id: any) {
    // Check if ID id undefined or null
    if (id) {
      // Check the length of the ID number
      if (id.length !== 13) {
        return false;
      }
    } else {
      return false;
    }

    // Get the first 6 digits to validate the date
    const tempDate = new Date(id.substring(0, 2), id.substring(2, 4) - 1, id.substring(4, 6));
    const idDate = tempDate.getDate();
    const idMonth = tempDate.getMonth();
    if (!((tempDate.getFullYear().toString().substring(2, 4) === id.substring(0, 2)) &&
      (idMonth === id.substring(2, 4) - 1) &&
      (idDate === parseInt(id.substring(4, 6), 10)))) {
      return false;
    }

    // apply Luhn formula
    let tempTotal = 0;
    let checkSum = 0;
    let multiplier = 1;
    for (let i = 0; i < 13; ++i) {
      tempTotal = parseInt(id.charAt(i), 10) * multiplier;
      if (tempTotal > 9) {
        tempTotal = parseInt(tempTotal.toString().charAt(0), 10) + parseInt(tempTotal.toString().charAt(1), 10);
      }
      checkSum = checkSum + tempTotal;
      multiplier = (multiplier % 2 === 0) ? 1 : 2;
    }

    if ((checkSum % 10) !== 0) {
      return false;
    }

    return true;
  }
  nameValidator(control: FormControl): { [s: string]: boolean } {
    const nameRegex = /^[a-zA-Z _-]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (!nameRegex.test(control.value) && control.value) {
      return { nameValidator: true };
    }
    return null;
  }

  getGenericPresignedurls(filesToUpload, folder): Promise<any> {

    return this.http.post(`${this.baseUrl}data/media`, { fileExtension: filesToUpload.fileExtension, folder: folder }, { headers: this.headers }).toPromise();

  }

  genericUploadFile(url: string, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formdata, { headers: header }).toPromise();
  }




  GetAllSkills(): Promise<any> {
    return this.http.get(`${this.baseUrl}skill`, { headers: this.headers }).toPromise();

  }


  getFocusesById(sectorId)
  {
    return this.http.get(`${this.baseUrl}lookups/focus`, { headers: this.headers,  params: new HttpParams().set('sector', sectorId)}).toPromise();
  }

  getSectors()
  {
    return this.http.get(`${this.baseUrl}lookups/sector`, { headers: this.headers}).toPromise();
  }

  createProffessionalRegistration(ProffessionalObject)
  {
    return this.http.post(`${this.baseUrl}professionalRegistration`,ProffessionalObject, {headers: this.headers}).toPromise();
  }
  getProfessionalPresignedurls(filesToUpload, folder): Promise<any> {

    return this.http.post(`${this.baseUrl}professionalRegistration/professionalRegistrationPostSigner`, { fileExtension: filesToUpload.fileExtension, folder: folder }, { headers: this.headers }).toPromise();

  }
  professionalUploadFile(url: string, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formdata, { headers: header }).toPromise();
  }
  getprofessionalRegistrationByUser(userId) {
    return this.http.get(`${this.baseUrl}professionalRegistration`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }
  getProjectsCompletedByUser(userId) {
    return this.http.get(`${this.baseUrl}projectCompleted`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }
  getMembershipsByUser(userId) {
    return this.http.get(`${this.baseUrl}membership`, {
      headers: this.headers,
      params: new HttpParams().set('userId', userId)
    }).toPromise();
  }

  deleteprofessionalRegistration(id) {
    return this.http.delete(`${this.baseUrl}professionalRegistration/${id}`, {
      headers: this.headers,
    }).toPromise();
  }
  deleteMembership(id) {
    return this.http.delete(`${this.baseUrl}membership/${id}`, {
      headers: this.headers,
    }).toPromise();
  }
  deleteProjectCompleted(id) {
    return this.http.delete(`${this.baseUrl}projectCompleted/${id}`, {
      headers: this.headers,
    }).toPromise();
  }
  createMembership(membershipObject)
  {
    return this.http.post(`${this.baseUrl}membership`,membershipObject, {headers: this.headers}).toPromise();
  }

  createProjectsCompleted(projects)
  {
    return this.http.post(`${this.baseUrl}projectCompleted`,projects, {headers: this.headers}).toPromise();
  }
  
  getMembershipPresignedurls(filesToUpload, folder): Promise<any> {

    return this.http.post(`${this.baseUrl}membership/membershipPostSigner`, { fileExtension: filesToUpload.fileExtension, folder: folder }, { headers: this.headers }).toPromise();

  }
  membershipCertificateUploadFile(url: string, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formdata, { headers: header }).toPromise();
  }

}


