import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import {ResetpasswordComponent} from '../../modal/resetpassword/resetpassword.component'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  mode = true;
  consented = false;
  personalDetailsForm: FormGroup;
  accountDetailsForm: FormGroup;
  error = {
    message: null,
    state: false
  };

  resp = {
    message: '',
    state: false
  };

  loading = false;

  timeOut;
  email;
  password;


  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.personalDetailsForm = this.formBuilder.group({
      firstName: [
        null, [
          Validators.pattern('^[a-zA-Z]+$'),
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(80),
        ]
      ],
      lastName: [
        null, [
          Validators.pattern('^[a-zA-Z]+$'),
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(80),
        ]
      ],
    });

    this.accountDetailsForm = this.formBuilder.group({

      emailAddress: [
        null, [
          Validators.email,
          Validators.required,
        ]
      ],
      password: [
        null, [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(60),
        ]
      ],
      confirmPassword: [
        null, [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(60),
        ]
      ],
      consented: [
        null, [
        ]
      ],

    });

  }

  toggle() {
    this.error.state = false;
    clearTimeout(this.timeOut);
    this.mode = !this.mode
  }

  formAlert(message) {
    this.error.message = message;
    this.error.state = true;
    this.timeOut = setTimeout(() => {
      this.error.state = false;
      clearTimeout(this.timeOut);
    }, 6000);

  }

  createAccount(formDirective1, formDirective2) {

    if (this.accountDetailsForm.value.password != this.accountDetailsForm.value.confirmPassword) {
      this.formAlert("Your passwords do not match")
      return
    };

    let user = {
      emailAddress: this.accountDetailsForm.value.emailAddress,
      firstName: this.personalDetailsForm.value.firstName,
      lastName: this.personalDetailsForm.value.lastName,
      role: "GENERAL",
      consent: {
        type: "TERMS-OF-USE",
        consent: true
      }
    };

    let password = this.accountDetailsForm.value.password;
    let email = this.accountDetailsForm.value.emailAddress;
    console.log('adding', user);
    this.loading = true;
    this.authService.userSignup(email, password).then(
      resp => {
        this.userService.createUser(user).then(
          resp => {
            this.resp.message = 'A link to verify your email has been sent to ' + resp.emailAddress + '. verify your email to complete your account setup.';
            this.personalDetailsForm.reset();
            this.accountDetailsForm.reset();
            formDirective1.resetForm();
            formDirective2.resetForm();
            this.loading = false;
            this.authService.sendEmailVerification();
            this.resp.state = true;
            this.mode = true;
            // this.router.navigate(['/specialist']);
          },
          error => {
            console.log(error);
            this.formAlert(error.error.message);
            this.loading = false;
          }
        ).catch(error => { console.log(error), this.loading = false; });
      }
    ).catch(error => {
      this.loading = false;
      let errorMessage = this.authValidator(error);
      this.formAlert(errorMessage);
    })

  }

  signIn() {
    this.resp.state = false;
    this.loading = true;
    this.authService.userSignin(this.email, this.password).then(
      firebaseUser => {
        let user = firebaseUser['user'];
        if (user['emailVerified'] == false) {
          this.resp.message = 'A link to verify your email has been sent to ' + user['email'] + '. verify your email before you can login.';
          this.email = null;
          this.password = null;
          this.resp.state = true;
          this.loading = false;
          return;
        }
        //console.log(this.email);

        this.userService.getUserByEmail(this.email).then(
          resp => {
            //console.log(resp);

            if (resp.users.length > 0) {
              let user = resp.users[0]

              if (user.profilePicture != null) {
                this.userService.getUploadedProfilePicture(user.profilePicture.key).then(
                  resp => {
                    //console.log(resp);
                    
                    this.userService.setbase64Uri('data:image/jpeg;base64,' + resp);

                    //localStorage.setItem(this.landlord.registrationNumber, 'data:image/jpeg;base64,' + resp);
                  
                    this.userService.setUser(user);
                    this.authService.setAuthState(true);
                    this.loading = false;
                    this.router.navigate(['/specialist/dashboard']);
                  }
                ).catch(
                  error =>{
                    console.log(error);
                    
                  }
                )
              } else {
                this.userService.setUser(resp.users[0]);
                this.authService.setAuthState(true);
                this.loading = false;
                this.router.navigate(['/specialist/dashboard']);
              }
            } else {
              this.loading = false;
              this.formAlert('There was an issue feaching your profile, please try again later.')
            }
          }
        ).catch(
          error => {
            console.log(error);

            this.loading = false;
            this.formAlert('There was an issue feaching your profile, please try again later.')
          }
        )

      },
    ).catch(
      error => {
        this.loading = false;
        console.log(error);

        let errorMessage = this.authValidator(error);
        this.formAlert(errorMessage);
      }
    )
  }

 resetPassword()
 {
  this.dialog.open(ResetpasswordComponent, {
    panelClass: 'custom-dialog-container'
  });
 }

  authValidator(error) {
    switch (error['code']) {
      case "auth/wrong-password":
        return "Invalid email address or password.";
        break;
      case "auth/invalid-email":
        return "Please enter a valid email address.";
        break;
      case "auth/user-not-found":
        return "Please create an account first.";
        break;
      case "auth/weak-password":
        return "Password should be at least 6 characters.";
        break;
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
        break;
      case "auth/uid-already-exists":
        return "The email address is already in use by another account.";
        break;
      case "auth/network-request-failed":
        return "Please make sure you are connected to the internet";
        break;
      case "auth/argument-error":
        return "Please fill your creditials";
        break;
      default:
        return "Something went wrong, please try again later";

    }
  }

}
