import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-landing-services',
  templateUrl: './landing-services.component.html',
  styleUrls: ['./landing-services.component.scss']
})
export class LandingServicesComponent implements OnInit {
mobile;
  constructor() { }

  ngOnInit() {
    if (window.innerWidth < 680) {
      this.mobile = true;
    }
    $(window).scroll( function () {

      var wScroll = $(window).scrollTop();
      
      if (wScroll >= 10) {
        $('.nav-wrapper').addClass('sticky-nav');

      } else {
        $('.nav-wrapper').removeClass('sticky-nav');

      }

    });
  }

  scroll(el: HTMLElement) {

    window.scrollTo({
      top: el.offsetTop - 80,
      behavior: "smooth"
    });
  }

}
