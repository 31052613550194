import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  options = { autoHide: false, scrollbarMinSize: 70 };
  constructor() { }

  ngOnInit() {
  }

}
