import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'projects/specialist/src/app/services/user/user.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {
  activeUser;
  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialog, private userSrvc: UserService) { }

  ngOnInit() {
    this.userSrvc.getUser().subscribe(res => {
      this.activeUser = res;

      console.log(this.activeUser);
      this.loading = false;
    });
  }
  submitted = null;
  profilePicture = {
    preview: null,
    file: null,
    extension: null,
    fileName: null
  };

  profileUpload(event) {

    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1];

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.profilePicture = {
          
          preview: event.target.result,
          file: file,
          extension: extension,
          fileName: filename
        };
      }
      reader.readAsDataURL(event.target.files[0]);

    }


    if (this.profilePicture.file) {
      this.uploadProfilePicture().then(
        profileObj => {
          //console.log(profileObj);
          this.activeUser.profilePicture = profileObj;

        });

      return
    }

    console.log(this.profilePicture);
  }

  // Upload Profile Picture
  uploadProfilePicture() {
    this.submitted = "sending";
    this.loading = true;
   
    let dataObject;
    var formData: any = new FormData();

    return new Promise((resolve, reject) => {

      this.userSrvc.profilePictureUrl(this.profilePicture.extension).then(dataObject => {

        let fileKey = dataObject.id;
        Object.keys(dataObject.presignedPost.fields).forEach(key => {
          formData.append(key, dataObject.presignedPost.fields[key])
        });

        formData.append('file', this.profilePicture.file);

        this.userSrvc.uploadProfilePicture(dataObject.presignedPost.url, formData).then(data => {

          let profileObj = {
            key: formData.get('key'),
            fileName: this.profilePicture.fileName,
            extension: this.profilePicture.extension
          };
          console.log(profileObj);

          this.activeUser.profilePicture = profileObj;
          let id = this.activeUser.id;

          this.userSrvc.getUploadedProfilePicture(this.activeUser.profilePicture.key).then(
            resp => {
              this.profilePicture.preview = 'data:image/jpeg;base64,' + resp;
              this.userSrvc.setbase64Uri('data:image/jpeg;base64,' + resp)
            }
          )

          this.userSrvc.updateUser(id, this.activeUser).then(resp => {
            console.log(resp);
            this.userSrvc.setUser(resp);
            this.loading = false;
            this.submitted = "done"  

          }).catch(error => {
            console.log(error);
            this.submitted = "error";
            this.loading = false
          });
          this.profilePicture.file = null;

          resolve(profileObj);

        });

      }).catch(
        error => {
          this.submitted = "error";
          console.log(error);

        }
      )
    })
  }


 
}
