import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { UserService } from 'projects/specialist/src/app/services/user/user.service';
import { SpecializationTagsService } from 'projects/specialist/src/app/services/specialization-tags/specialization-tags.service';
import { AuthService } from 'projects/specialist/src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SpecialistDialogModalComponent } from '../../../../modal/specialist-dialog-modal/specialist-dialog-modal.component';
import { element } from 'protractor';
import { MatSelectChange, MatStepper, MatSnackBar, ErrorStateMatcher } from '@angular/material';
import { EditEmploymentComponent } from '../../employment/edit-employment/edit-employment.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, startWith, map, switchMap } from 'rxjs/operators';

declare var $: any;



export class PersonalFormStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  panelOpenState = false;
  level = [
    { id: 'MATRICCERTIFICATE', name: 'MATRIC CERTIFICATE' },
    { id: 'HIGHERCERTIFICATE', name: 'HIGHER CERTIFICATE' },
    { id: 'NATIONALDIPLOMA', name: 'NATIONAL DIPLOMA' },
    { id: 'BACHELORSDEGREE', name: 'BACHELORS DEGREE' },
    { id: 'HONOURSDEGREE', name: 'HONOURS DEGREE' },
    { id: 'MASTERSDEGREE', name: 'MASTERS DEGREE' },
    { id: 'DOCTORALDEGREE', name: 'DOCTORAL DEGREE' }
  ];

  isLinear = false;
  user;
  personalDetailsForm: FormGroup;
  personAddressForm: FormGroup;
  specialisationForm: FormGroup;
  QualificationsForm: FormGroup;
  BiographyForm: FormGroup;
  workHistoryForm: FormGroup;
  ServiceLevelAgreement: FormGroup;
  SkillForm: FormGroup;
  SectorsForm: FormGroup;
  identificationType;
  updatePersonalDetails = false;
  updateAddressDetails = false;
  updateSpecialisationDetails = false;
  updateQualificationsDetails = false;
  selectedQualification = null;
  selectedDocument = null;
  selectedDocuments = [null, null];
  serviceTypes = [];
  disciplines = [];
  selectedDisciplines = [];
  industry;
  departments;
  selectedQualifications = [];
  selectedServiceTypes = [];
  selectedDepartments = [];
  selectedEmployments = [];
  traitPath = [];
  userExistingTraitPath = [];
  userDepartments = [];
  userDisciplines = [];
  userExistingDepartments = [];
  userExistingDisciplines = [];
  loading = false;
  load = false;
  duties = [];
  skills = [];
  userSkills = [];
  sectors;
  focus;
  ProffessionalSector;


  duty;
  workExperinces = []
  userQualifications = [];
  slaAgreement = false;
  selectedId = null;
  selectedCV = null;
  SLAArr = [
    "The YaBatho Advisory Services Platform (YB) is operated exclusively by Tshwane University of Technology Enterprise Holdings TUTEH (PTY) LTD.",
    "YB is designed to provide a repository of Academic and Private Sector Professionals, referred to as Specialists, and collectively market these capabilities to Prospect Clients.",
    "Specialists provide their Professional Profile and availability for purposes of YB collectively marketing these platform wide capabilities to Prospect Clients requiring specialist services",
    "YB provides services to the Public and Private Sectors across a host of disciplines constituted by the Specialist capabilities and interests.",
    "Personal Information excl. profile picture of Specialists is protected and confidential and will only be disclosed to Prospect Clients on a case for case basis, after the Specialist agrees to same.",
    "The Specialists biography will be accompanied by their profile picture and be accessible by the public.",
    "A formal request with motivation will be made directly to the Specialist by the Client Relations Specialist of the YB Platform, following normal email communication protocols.",
    "The information requested by the Prospect Client would normally relate to qualifications and experience to match the opportunity, but only on the express permission of the Specialist, recorded by YB, for audit purposes.",
    "Specialists provide availability schedules for purposes of YB consolidating a Platform Wide Skills Matrix, normally recorded as hours per month for a 12-month period.",
    "A Service Level Agreement (SLA) will be tendered for each Project by YB for consideration by the Specialist, including Project Scope, Duration, Allocated Hours, Deliverables and Remuneration.",
    "On signature by the parties the project commences following normal Commercial and Technical processes",
    "All Reporting and Delivery Protocols will be defined in the SLA.",
    "A Project Officer will be assigned by YB to coordinate all activities between the Customer and Specialists assigned to the Project."
  ];

  myControl = new FormControl();
  options: string[];
  filteredOptions: Observable<string[]>;


  @ViewChild('stepper', { static: true }) private myStepper: MatStepper;
  constructor(
    private frmBldr: FormBuilder,
    private userSrvc: UserService,
    private specialistTagSrvc: SpecializationTagsService,
    private dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar) {


    this.initFormGroups();

    this.specialistTagSrvc.getAllService().then(
      resp => {
        //console.log(resp);
        this.serviceTypes = resp.industries
      }
    );

    this.specialistTagSrvc.getAllDesciplines().then(
      resp => {

        this.disciplines = resp.disciplines;
        console.log(this.disciplines);
      }
    );




  }

  nextStep() {
    this.myStepper.next();
  }
  ngOnInit() {
    this.userSrvc.getSkillsObservable().subscribe(
      resp => {
        console.log(resp);
        this.options = resp;

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );

      }
    );

    this.getLoggedInUser()

    this.userSrvc.getSectors().then(data => {
      this.sectors = data;
      this.sectors = this.sectors.sectors;
      console.log(this.sectors)
    })



  }

  userExistingFocuses = [];
  userExistingSectors = [];
  getLoggedInUser() {
    this.userSrvc.getUser().subscribe(
      resp => {
        this.user = resp;
        if (this.user.skills.length > 0 || this.user.skills) {
          this.userSkills = this.user.skills;
          console.log(this.skills);;
          this.skills = [];
          this.userSkills.forEach(
            element => {
              this.skills.push(
                { skill: element }

              )
            }
          );
        }



        // SLA 
        if (this.user.consents.length <= 1) {
          this.slaAgreement = false;

        } else if (this.user.consents[1].consent === true) {
          this.slaAgreement = true;
        }

        this.GetUserWorkExperiences(this.user.id);
        this.GetUserQualifications(this.user.id)
        this.setPersonalDetailsFormValues();
        this.setAddressDetailsFormValues();
        this.setBiographyFormValue();
        // this.setSpecialisationFormValues();

        this.userExistingDepartments = this.user.departments;
        this.userExistingDisciplines = this.user.disciplines;

        if (this.user.sectors != undefined && this.user.sectors != null) {
          this.userExistingSectors = this.user.sectors;
          this.userExistingFocuses = this.user.focuses;
        }



      }
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }




  addToQueue() {
    this.duties.unshift(
      this.duty
    );
    this.duty = null
  }
  removeItem(i) {
    this.duties.splice(i, 1)
  }
  addselectedEmployment() {
    if (this.workHistoryForm.status == "VALID") {
      this.selectedEmployments.push(
        {
          userId: this.user.id,
          duration: parseInt(this.workHistoryForm.value.numberOfYears),
          company: this.workHistoryForm.value.companyName,
          position: this.workHistoryForm.value.position,
          duties: this.duties,
        }
      );
      this.duties = [];
      //console.log(this.selectedEmployments);
      this.workHistoryForm.controls['numberOfYears'].reset();
      this.workHistoryForm.controls['companyName'].reset();
      this.workHistoryForm.controls['position'].reset();

    } else if (this.workHistoryForm.status == "INVALID") {
      console.log(this.workHistoryForm);
      this.myAlert("Please enter valid work history information to proceed");
    }

  }
  removeSelectedEmployment(i) {
    this.selectedEmployments.splice(i, 1)
  }

  removeEmployment(id, i) {
    this.loading = true;
    //console.log(this.selectedEmployments);
    this.userSrvc.deleteWorkExperience(id).then(resp => {
      this.workExperinces.splice(i, 1)
      this.loading = false
    }).catch(error => {
      //console.log(this.selectedEmployments);
      //console.log(error);
    })
  }


  submitWorkHistory() {

    this.loading = true;
    let userId = this.user.id;
    this.userSrvc.updateUserWorkExperience(this.selectedEmployments).then(resp => {


      this.GetUserWorkExperiences(userId);
      this.selectedEmployments = []
      this.loading = false
      this.nextStep()
      this.myAlert("Work History details updated successfully");
    }).catch(error => {
      this.myAlert("Your details could not be updated, please try again later");
    })
  }



  SelectedDescipline(event: MatSelectChange) {

    let id = event.value.id;

    this.load = true;
    this.specialistTagSrvc.getAllDepartment(id).then(res => {
      this.departments = res;
      this.departments = this.departments.department;
      this.load = false;
    });
  }
  addTraitPath() {
    let departments = [];
    this.selectedDepartments.forEach(
      element => {
        departments.push(
          element
        )
      }
    );
    let disciplineHold = this.specialisationForm.value.discipline;

    this.traitPath.push(
      {
        discipline: {
          id: disciplineHold.id,
          name: disciplineHold.discipline
        },
        departments: departments.length > 0 ? departments : this.user.departments ? this.user.departments : [],
      })
    this.selectedDepartments = []
    this.specialisationForm.value
    this.specialisationForm.controls['discipline'].reset();
    this.departments = []
    console.log(this.traitPath);
  }

  sectorPath = [];

  addSectorPath() {
    let focuses = [];
    let focusHold;
    this.selectedFocus.forEach(
      element => {
        focuses.push(element)
      }
    );
    console.log(focuses);

    if (focuses.length > 0) {
      focusHold = focuses;
    }
    else {
      focusHold = [];
    }

    let sectorHold = this.SectorsForm.value.sector;

    this.sectorPath.push(
      {
        sector: {
          id: sectorHold.id,
          name: sectorHold.sector,
          experienceDescription: this.SectorsForm.value.sectorExperience
        },
        focuses: focusHold

      });
    console.log(this.sectorPath);
  }
  userFocuses = [];
  userSectors = [];

  submitSectors() {
    this.loading = true;
    this.userFocuses = [];
    this.userSectors = [];

    this.sectorPath.forEach(
      element => {
        this.userFocuses = this.userFocuses.concat(element.focuses)
        this.userSectors.push(
          element.sector,
        )
      }
    );

    this.userFocuses = this.userFocuses.concat(this.userExistingFocuses);
    this.userSectors = this.userSectors.concat(this.userExistingSectors);

    console.log(this.user);

    this.user.sectors = this.userSectors ? this.userSectors : this.user.sectors ? this.user.sectors : null;
    this.user.focuses = this.userFocuses.length > 0 ? this.userFocuses : this.user.focuses ? this.user.focuses : [];

    let userId = this.user.id;
    this.userSrvc.updateUser(userId, this.user).then(
      resp => {

        this.loading = false;
        this.myAlert("Sectors updated successfully");
        this.getLoggedInUser();
        this.userSectors = [];
        this.userFocuses = [];
        this.sectorPath = [];
        this.loading = false;

      }
    ).catch(
      error => {
        console.log(error);

        this.loading = false;
        this.myAlert("Your details could not be updated, please try again later");
      }
    );
  }





  removeTraitPath(i) {
    this.traitPath.splice(i, 1)
  }
  removeExistingDisciplines(i) {
    this.userExistingDisciplines.splice(i, 1)
  }
  removeExistingDepartments(i) {
    this.userExistingDepartments.splice(i, 1)
  }
  removeExistingFocuses(i) {
    this.userExistingFocuses.splice(i, 1)
  }
  removeExistingSectors(i) {
    this.userExistingSectors.splice(i, 1)
  }
  selectedIdentificationType;


  SelectedSector(event: MatSelectChange) {

    let id = event.value.id
    this.ProffessionalSector = event.value.sector;

    if (this.ProffessionalSector === "Other Sector") {
      this.SectorsForm.controls['otherSector'].setValidators([
        Validators.required,

      ]);
    }
    else if (this.ProffessionalSector === "Private Sector" || this.ProffessionalSector === "Public Sector") {
      this.SectorsForm.controls['otherSector'].clearValidators();
    }


    this.userSrvc.getFocusesById(id).then(data => {
      this.focus = data;
      this.focus = this.focus.focus;

    })

  }

  SelectedIdentification(event: MatSelectChange) {
    this.selectedIdentificationType = event.value;
    //console.log(this.selectedIdentificationType);

    let condition = event.value;
    console.log(condition);


    if (condition == "southAfricaID") {
      this.personalDetailsForm.controls['passportNumber'].clearValidators();
      this.personalDetailsForm.controls['citizenship'].clearValidators();
      this.personalDetailsForm.controls['idNumber'].setValidators([
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.minLength(13),
        Validators.maxLength(13),
        this.checkInvalidId.bind(this),
      ]);
      console.log(this.personalDetailsForm.controls);

    } else {
      this.personalDetailsForm.controls['idNumber'].clearValidators();
      this.personalDetailsForm.controls['passportNumber'].setValidators([
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(2),

      ]);
      this.personalDetailsForm.controls['citizenship'].setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern('^[a-zA-Z ]*$')

      ]);
      console.log(this.personalDetailsForm.controls);



    }
  }


  selectedFocus = [];
  addFocus(event) {

    let index = -1;

    this.selectedFocus.forEach(
      (element, i) => {
        if (element.id === event.id) {
          index = i;
          console.log("found");

        }
      }
    );

    if (index >= 0) {
      this.selectedFocus.splice(index, 1)
    } else {
      this.selectedFocus.push({
        id: event.id,
        name: event.focus
      })
    }

    console.log(this.selectedFocus);

  }


  selectedSector = [];
  otherSectors = [];
  SectorExperiences = [];


  addDepartment(event) {
    let index = -1;
    this.selectedDepartments.forEach(
      (element, i) => {
        if (element.id === event.id) {
          index = i;
          console.log("found");
        }
      }
    );
    if (index >= 0) {
      this.selectedDepartments.splice(index, 1)
    } else {
      this.selectedDepartments.push({
        id: event.id,
        name: event.department
      })
    }
  }


  addServiceType(event) {

    let index = this.selectedServiceTypes.indexOf(event.id);
    if (index >= 0) {
      this.selectedServiceTypes.splice(index, 1)
    } else {
      this.selectedServiceTypes.push(
        event.id
      )
    }
    console.log(this.selectedServiceTypes);

  }


  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }


  // editQualifications(element){
  //   this.dialog.open(EditEmploymentComponent, { autoFocus: false, data: element }).afterClosed().subscribe(resp => {
  //   });
  // }

  removeSelectedQualification(i) {
    this.selectedQualifications.splice(i, 1)
  }

  removeQualification(id, i) {
    this.loading = true;
    this.userSrvc.deleteQualification(id).then(resp => {
      this.userQualifications.splice(i, 1)
      this.loading = false
    }).catch(error => {
    })
  }


  // add file selected to the array of documents
  AddDocumentFiles() {
    this.selectedDocuments.push({


    });

    //console.log(this.selectedDocuments);
  }




  addselectedQualifications() {
    if (this.QualificationsForm.status == "VALID") {
      this.selectedQualifications.push(
        {
          userId: this.user.id,
          course_name: this.QualificationsForm.value.courseName,
          level: this.QualificationsForm.value.level,
          institution: this.QualificationsForm.value.institution,
          fileObj: {
            filename: this.selectedQualification.fileName,
            fileExtension: this.selectedQualification.extension,
            file: this.selectedQualification.file
          }
        }
      );

      //console.log(this.selectedQualifications);

      this.selectedQualification = null;
      this.QualificationsForm.controls['courseName'].reset();
      this.QualificationsForm.controls['level'].reset();
      this.QualificationsForm.controls['institution'].reset();
    } else if (this.QualificationsForm.status == "INVALID") {
      console.log(this.QualificationsForm);
      this.myAlert("Please enter valid qualification information to proceed");
    }
  }

  // meth
  onFileSelectedDocuments(event, index) {
    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedDocument = {
      file: file,
      fileName: filename,
      fileExtension: extension
    }

    this.selectedDocuments[index] = {
      fileObj: {
        filename: filename,
        fileExtension: extension,
        file: file
      }
    }

  }

  //id select
  onIdFileSelectedDocuments(event) {
    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedId = {
      file: file,
      fileName: filename,
      fileExtension: extension,
    }
    //console.log(this.selectedId)

  }

  //CV select
  onCVFileSelectedDocuments(event) {
    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedCV = {
      file: file,
      fileName: filename,
      fileExtension: extension,
    }
    //console.log(this.selectedCV)
  }

  getCVPresignedurl(): Promise<any> {


    return new Promise((resolve, reject) => {

      this.userSrvc.getGenericPresignedurls(this.selectedCV, "CV").then(presignUrl => {
        //console.log(presignUrl);

        var formData: any = new FormData();

        Object.keys(presignUrl['presignedPost']['fields']).forEach(key => {
          formData.append(key, presignUrl['presignedPost']['fields'][key])
        });

        this.selectedCV.key = presignUrl.id;
        formData.append('file', this.selectedCV.file);
        //console.log("formdata", formData);
        this.userSrvc.genericUploadFile(presignUrl.presignedPost.url, formData).then(
          data => {
            //console.log(data);

            resolve(presignUrl);
          }, error => {
            //console.log(error);
            reject(error)

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });
  }

  getIdPresignedurl(): Promise<any> {


    return new Promise((resolve, reject) => {

      this.userSrvc.getGenericPresignedurls(this.selectedId, "id-document").then(presignUrl => {
        //console.log(presignUrl);

        var formData: any = new FormData();

        Object.keys(presignUrl['presignedPost']['fields']).forEach(key => {
          formData.append(key, presignUrl['presignedPost']['fields'][key])
        });

        this.selectedId.key = presignUrl.id;
        formData.append('file', this.selectedId.file);
        //console.log("formdata", formData);
        this.userSrvc.genericUploadFile(presignUrl.presignedPost.url, formData).then(
          data => {
            //console.log(data);

            resolve(presignUrl);
          }, error => {
            //console.log(error);
            reject(error)

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });
  }


  onFileSelectedQualifications(event) {

    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedQualification = {
      file: file,
      fileName: filename,
      extension: extension
    }

    //console.log(this.selectedQualification);



  }

  getDocumentsPresignedurl(): Promise<any> {


    let formDataArr = [];
    this.selectedDocuments.forEach(
      element => {
        //console.log(element);
        formDataArr.push(
          { formData: new FormData() }
        )
      }
    );


    return new Promise((resolve, reject) => {
      let formsDataArr = [];

      this.userSrvc.GetDocumentsPresignedUrl(this.selectedDocuments).then(presignUrls => {
        //console.log(presignUrls);
        if (presignUrls) {
          for (let i = 0; i < presignUrls.length; i++) {

            var formData: any = new FormData();

            Object.keys(presignUrls[i]['presignedPost']['fields']).forEach(key => {
              formData.append(key, presignUrls[i]['presignedPost']['fields'][key])
            });

            this.selectedDocuments[i].fileObj.key = presignUrls[i].id;
            formData.append('file', this.selectedDocuments[i].fileObj.file);

            formsDataArr.push(
              {
                data: formData,
                url: presignUrls[i]['presignedPost']['url']
              }
            );
          }
        }
        this.userSrvc.uploadQualificationFile(formsDataArr).then(
          data => {
            //console.log(data);

            resolve(presignUrls);
          }, error => {
            //console.log(error);

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });
  }

  getQualificationPresignedUrl(): Promise<any> {


    let formDataArr = [];
    this.selectedQualifications.forEach(
      element => {
        //console.log(element);
        formDataArr.push(
          { formData: new FormData() }
        )
      }
    );

    return new Promise((resolve, reject) => {
      let formsDataArr = [];
      this.userSrvc.GetQualificationsPresignedUrl(this.selectedQualifications).then(presignUrls => {
        //console.log(presignUrls);
        if (presignUrls) {
          for (let i = 0; i < presignUrls.length; i++) {

            var formData: any = new FormData();

            Object.keys(presignUrls[i]['presignedPost']['fields']).forEach(key => {
              formData.append(key, presignUrls[i]['presignedPost']['fields'][key])
            });

            this.selectedQualifications[i].fileObj.key = presignUrls[i].id;
            formData.append('file', this.selectedQualifications[i].fileObj.file);

            formsDataArr.push(
              {
                data: formData,
                url: presignUrls[i]['presignedPost']['url']
              }
            );
          }
        }
        this.userSrvc.uploadQualificationFile(formsDataArr).then(
          data => {
            //console.log(data);

            resolve(data);
          }, error => {
            //console.log(error);

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });

  }

  SubmitQualificationDocuments() {
    this.loading = true;
    this.getQualificationPresignedUrl().then(resp => {
      let id = this.user.id;
      //console.log(resp);

      let qualifications = [];
      this.selectedQualifications.forEach((element, i) => {

        qualifications.push(
          {
            userId: this.user.id,
            course_name: element.course_name,
            level: element.level,
            institution: element.institution,
            qualificationFile: {
              key: element.fileObj.key,
              fileName: element.fileObj.filename,
              extension: element.fileObj.fileExtension
            }

          }
        )
      });

      this.userSrvc.updateUserQualifications(qualifications).then(resp => {
        //console.log(resp);
        this.loading = false
        this.GetUserQualifications(id);
        this.selectedQualifications = []
        this.nextStep();
        this.myAlert("Qualifications details updated successfully");

      }).catch(error => {
        this.loading = false
        this.myAlert("Your details could not be updated, please try again later");
        //console.log(qualifications);
        //console.log(error);
      })


      //console.log(resp)
    }).catch(error => {
      //console.log(error)
      this.loading = false
    }
    )
  }


  initFormGroups() {

    this.personalDetailsForm = this.frmBldr.group({


      title: [
        null, Validators.required
      ],

      firstName: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z _-]*$'),
          Validators.maxLength(50),
          Validators.minLength(2)
        ]
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z _-]*$'),
          Validators.maxLength(50),
          Validators.minLength(2)
        ]

      ],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9+]*$'),
          Validators.minLength(5),
          Validators.maxLength(15),
        ]
      ],
      gender: [null,
        [Validators.required]
      ],
      idNumber: [
      ],
      passportNumber: [null,],
      citizenship: [null,]

    });

    this.personAddressForm = this.frmBldr.group({
      streetNumber: [null,
        [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(7),]],
      suburb: [null,
        [
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.minLength(5),
          Validators.maxLength(255),
          Validators.required,
        ]],
      line2: [null,
        [
          Validators.minLength(5),
          Validators.maxLength(255),
        ]],
      line1: [null,
        [
          Validators.minLength(5),
          Validators.maxLength(255),
          Validators.required,
        ]],
      city: [null,
        [
          Validators.minLength(5),
          Validators.maxLength(255),
          Validators.required,
        ]],
      state: [null,
        [
          Validators.minLength(5),
          Validators.maxLength(255),
          Validators.required,
        ]],
      country: [null,
        [
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.minLength(5),
          Validators.maxLength(255),
          Validators.required,
        ]],
      postalCode: [null,
        [
          Validators.pattern('[0-9]*'),
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.required,
        ]]
    })

    this.specialisationForm = this.frmBldr.group({
      discipline: [null,
      ]

    })

    this.QualificationsForm = this.frmBldr.group({
      courseName: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(5)
        ]
      ],
      level: [null,
        [Validators.required,]],


      institution: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(5),
          Validators.pattern('^[a-zA-Z _-]*$'),
        ]],
    });


    this.workHistoryForm = this.frmBldr.group({
      companyName: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(1)
        ]
      ],
      numberOfYears: [null,

        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.maxLength(4),
          Validators.minLength(1)
        ]
      ],
      position: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),
        ],


      ],
      duties: [
        null,
        [
          Validators.maxLength(255),
          Validators.minLength(3)
        ],
      ],
      duty: [
        null,
        [
          Validators.maxLength(255),
          Validators.minLength(3)
        ],
      ],
    });

    this.BiographyForm = this.frmBldr.group({
      monthlyHours: [null,
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(1),
          Validators.maxLength(3)
        ]],
      biography: [null,
        [Validators.required,
        Validators.maxLength(600),
        Validators.minLength(5)
        ]],
    });


    this.SectorsForm = this.frmBldr.group({
      sector: [null,
        [Validators.required]
      ],
      sectorExperience: [null,
        [
          Validators.required
        ]
      ],
      otherSector: [

      ]
    })

  }


  setPersonalDetailsFormValues() {

    if (this.user.idNumber != null || this.user.passportNumber != null) {
      if (this.user.idNumber) {

        this.identificationType = "southAfricaID";
        this.personalDetailsForm.controls['passportNumber'].clearValidators();
        this.personalDetailsForm.controls['idNumber'].setValidators([
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(13),
          Validators.maxLength(13),
          this.checkInvalidId.bind(this),
        ]);



      } else {

        this.identificationType = "passport";

        this.personalDetailsForm.controls['idNumber'].clearValidators();
        this.personalDetailsForm.controls['passportNumber'].setValidators([
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),

        ]);
        this.personalDetailsForm.controls['citizenship'].setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z ]*$')

        ]);
      }
    }

    this.personalDetailsForm.setValue({
      title: this.user.title,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      idNumber: this.user.idNumber,
      phoneNumber: this.user.contactNumber,
      gender: this.user.gender,
      passportNumber: this.user.passportNumber,
      citizenship: this.user.citizenship
    });
  }

  setAddressDetailsFormValues() {

    if (this.user.address === null) {
      return
    }

    this.personAddressForm.setValue({
      streetNumber: this.user.address.streetNumber,
      country: this.user.address.country,
      line1: this.user.address.line1,
      line2: this.user.address.line2,
      city: this.user.address.city,
      state: this.user.address.state,
      postalCode: this.user.address.postalCode,
      suburb: this.user.address.suburb
    });
  }

  setBiographyFormValue() {

    if (this.user.monthlyHours && this.user.biography) {
      this.BiographyForm.setValue({
        monthlyHours: this.user.monthlyHours,
        biography: this.user.biography
      })
    }
    else {
      console.log("nothing to set");
    }


  }

  setSpecialisationFormValues() {

    this.specialisationForm.setValue({
      // discipline: this.user.discipline,
    });

  }






  submitPersonalDetails() {


    if (this.personalDetailsForm.status == "VALID") {
      this.loading = true;
      if (this.identificationType === "southAfricaID") {
        this.user.passportNumber = null;
        this.user.citizenship = "Republic of South Africa"
        this.user.idNumber = this.personalDetailsForm.value.idNumber;

      } else if (this.identificationType === "passport") {
        this.user.idNumber = null;

        this.user.passportNumber = this.personalDetailsForm.value.passportNumber;
        this.user.citizenship = this.personalDetailsForm.value.citizenship;
      }


      this.user.title = this.personalDetailsForm.value.title;
      this.user.firstName = this.personalDetailsForm.value.firstName;
      this.user.lastName = this.personalDetailsForm.value.lastName;
      this.user.gender = this.personalDetailsForm.value.gender;
      this.user.contactNumber = this.personalDetailsForm.value.phoneNumber;
      this.user.role = "SPECIALIST"


      let userId = this.user.id;
      this.userSrvc.updateUser(userId, this.user).then(
        resp => {
          //console.log(resp);
          this.updatePersonalDetails = false;
          this.selectedDocuments = [];
          this.setPersonalDetailsFormValues();
          this.nextStep();
          this.loading = false;
          this.myAlert("Personal details updated successfully");


        }
      ).catch(
        error => {
          //console.log(error);

          this.myAlert("Your Personal details could not be updated, please try again later");
          this.loading = false;

        }
      );

    } else if (this.personalDetailsForm.status == "INVALID") {
      console.log(this.personalDetailsForm);
      this.myAlert("Please enter valid Personal details information to proceed");
    }
  }


  submitAddressDetails() {

    if (this.personAddressForm.status === "VALID") {
      this.loading = true;
      this.user.address = {
        city: this.personAddressForm.value.city,
        country: this.personAddressForm.value.country,
        line1: this.personAddressForm.value.line1,
        line2: this.personAddressForm.value.line2,
        postalCode: this.personAddressForm.value.postalCode,
        state: this.personAddressForm.value.state,
        streetNumber: this.personAddressForm.value.streetNumber,
        suburb: this.personAddressForm.value.suburb,
        longitude: 0,
        latitude: 0
      };

      let userId = this.user.id;
      this.userSrvc.updateUser(userId, this.user).then(
        resp => {
          //console.log(resp);
          this.setAddressDetailsFormValues();
          this.loading = false;
          this.nextStep();
          this.myAlert("Physical Address updated successfully");

        }

      ).catch(
        error => {
          //console.log(error);
          // //console.log(this.student);

          this.loading = false;
          this.myAlert("Your Physical Address details could not be updated, please try again later");

        });
    } else if (this.personAddressForm.status === "INVALID") {
      this.myAlert("Please enter valid Address information to proceed");
    }



  }


  GetUserWorkExperiences(id) {
    this.userSrvc.getWorkExperiences(id).then(result => {
      this.workExperinces = result['employments'];
      //console.log(this.workExperinces)
    }).catch(
      error => {
        //console.log(error);
      }
    );
  }

  GetUserQualifications(id) {
    this.userSrvc.getQualifications(id).then(result => {
      this.userQualifications = result['qualifications'];
      //console.log(this.userQualifications)
    }).catch(
      error => {
        //console.log(error);
      }
    );
  }
  uploadIdDocumet() {
    this.loading = true;
    if (this.selectedId != null) {

      this.getIdPresignedurl().then(data => {
        this.user.idObject = {
          key: data.id,
          fileName: this.selectedId.fileName,
          extension: this.selectedId.fileExtension
        }

        //console.log(this.user.idObject);
        let userId = this.user.id;
        this.userSrvc.updateUser(userId, this.user).then(
          resp => {
            //console.log(resp);
            this.loading = false;
            this.myAlert("ID updated successfully");
            this.selectedId = null;
          }
        ).catch(
          error => {
            //console.log(error);

            this.myAlert("Your details could not be updated, please try again later");
            this.loading = false;

          }
        );
      })
    }

  }
  removeID() {
    this.loading = true;
    this.user.idObject = null;
    let userId = this.user.id;
    this.userSrvc.updateUser(userId, this.user).then(
      resp => {
        //console.log(resp);
        this.loading = false;
        this.myAlert("ID deleted successfully");
        this.selectedId = null;
      }
    ).catch(
      error => {
        //console.log(error);

        this.myAlert("Your details could not be updated, please try again later");
        this.loading = false;

      }
    );

  }
  removeCV() {
    this.loading = true;
    this.user.slaObject = null;
    let userId = this.user.id;
    this.userSrvc.updateUser(userId, this.user).then(
      resp => {
        //console.log(resp);
        this.loading = false;
        this.myAlert("CV deleted successfully");
        this.selectedCV = null;
      }
    ).catch(
      error => {
        //console.log(error);

        this.myAlert("Your details could not be updated, please try again later");
        this.loading = false;

      }
    );

  }
  uploadCVDocumet() {

    if (this.selectedCV != null) {
      this.loading = true;

      this.getCVPresignedurl().then(data => {

        this.user.slaObject = {
          key: data.id,
          fileName: this.selectedCV.fileName,
          extension: this.selectedCV.fileExtension
        }
        //console.log(this.user.slaObject);
        let userId = this.user.id;
        this.userSrvc.updateUser(userId, this.user).then(
          resp => {
            //console.log(resp);
            this.loading = false;
            this.myAlert("CV updated successfully");

          }
        ).catch(
          error => {
            //console.log(error);

            this.myAlert("Your details could not be updated, please try again later");
            this.loading = false;

          }
        );
      })
    }
  }


  submitBiographyDetails() {
    if (this.BiographyForm.status === "VALID") {
      this.loading = true;
      this.user.biography = this.BiographyForm.value.biography;
      this.user.monthlyHours = parseInt(this.BiographyForm.value.monthlyHours);

      let userId = this.user.id;

      this.userSrvc.updateUser(userId, this.user).then(
        resp => {
          this.setBiographyFormValue();
          this.loading = false;
          this.nextStep();
          this.myAlert("Biography details updated successfully");

        }
      ).catch(
        error => {
          console.log(error);

          this.loading = false;
          this.myAlert("Your details could not be updated, please try again later");
        }
      );

    }
    else if (this.specialisationForm.status === "INVALID") {
      this.myAlert("Please enter valid biography details to proceed");
    }
  }

  submitProffessionalSkillsDetails() {
    if (this.skills.length > 0 && this.serviceTypes.length > 0) {
      this.loading = true;
      let serviceTypes = [];
      this.selectedServiceTypes.forEach(
        element => {
          serviceTypes.push({
            id: element
          })
        }
      );

      let selectedSkills = [];
      this.skills.forEach(
        element => {
          selectedSkills.push(
            element.skill
          )
        }
      );

      this.user.serviceTypes = serviceTypes.length > 0 ? serviceTypes : this.user.serviceTypes ? this.user.serviceTypes : [];
      this.user.skills = selectedSkills.length > 0 ? selectedSkills : this.user.skills ? this.user.skills : [];

      let userId = this.user.id;

      this.userSrvc.updateUser(userId, this.user).then(
        resp => {
          // this.setSpecialisationFormValues();
          this.loading = false;
          this.getLoggedInUser();
          this.myAlert("Proffessional Skills are updated successfully");



        }
      ).catch(
        error => {
          console.log(error);

          this.loading = false;
          this.myAlert("Your details could not be updated, please try again later");
        }
      );

    }
    else if (this.skills.length == 0 || this.serviceTypes.length == 0) {
      this.myAlert("Please enter valid skills before you can submit");

    }

  }

  submitSpecialisationDetails() {


    if (this.specialisationForm.status === "VALID") {
      this.loading = true;


      // let departments = [];
      // this.selectedDepartments.forEach(
      //   element => {
      //     departments.push({
      //       id: element
      //     })
      //   }
      // );

      //console.log(serviceTypes, departments);
      this.userDepartments = [];
      this.userDisciplines = [];

      this.traitPath.forEach(
        element => {
          this.userDepartments = this.userDepartments.concat(element.departments)
          this.userDisciplines.push(
            element.discipline
          )
        }
      );
      console.log(this.userDepartments);
      console.log(this.userDisciplines);
      this.userDepartments = this.userDepartments.concat(this.userExistingDepartments);
      this.userDisciplines = this.userDisciplines.concat(this.userExistingDisciplines);


      this.user.disciplines = this.userDisciplines ? this.userDisciplines : this.user.disciplines ? this.user.disciplines : null;
      this.user.departments = this.userDepartments.length > 0 ? this.userDepartments : this.user.departments ? this.user.departments : [];

      //console.log(this.user.serviceTypes);
      console.log(this.user.disciplines);

      console.log(this.user);
      let userId = this.user.id;
      //console.log(this.user)
      this.userSrvc.updateUser(userId, this.user).then(
        resp => {
          // this.setSpecialisationFormValues();
          this.loading = false;
          this.myAlert("Discipline details updated successfully");
          this.userDepartments = [];
          this.userDisciplines = [];
          this.traitPath = [];
        }
      ).catch(
        error => {
          console.log(error);

          this.loading = false;
          this.myAlert("Your details could not be updated, please try again later");
        }
      );
    } else if (this.specialisationForm.status === "INVALID") {
      this.myAlert("Please enter valid Specialisation details information to proceed");
    }




  }

  SubmitServiceLevelAgreement() {

    this.loading = true;
    //console.log(this.slaAgreement)
    if (this.user.idNumber != null && this.user.slaObject != null && this.user.idObject != null && this.user.sectors.length != 0
      && this.user.address != null && this.user.departments.length != 0
      && this.user.disciplines.length != 0 && this.user.skills.length != 0 && this.user.focuses.length != 0 && this.user.biography != null &&
      !this.user.consents[1]) {



      if (this.slaAgreement === true) {
        this.user.consent = {
          consent: true,
          type: "TERMS-OF-SERVICE"
        };


        //console.log(this.user);
        let userId = this.user.id;
        this.userSrvc.updateUser(userId, this.user).then(data => {
          //console.log(data);
          this.loading = false;
          this.myAlert("SLA details updated successfully");

          this.router.navigate(['/specialist/dashboard/profile']);
        }).catch(
          error => {
            //console.log(error);
            // //console.log(this.student);

            this.loading = false;
            this.myAlert("Your details could not be updated, please try again later");
            // this.analyticsSrvc.logEvent("profile_updated_student_failed");

          }
        );

      }
    } else {
      this.myAlert("Please complete profile before sigining SLA");
      this.loading = false;
    }
  }

  // Custom validator for ID number
  private checkInvalidId(control: FormControl): { [s: string]: boolean } {
    if (!this.userSrvc.validateID(control.value)) {
      return { checkInvalidId: true };
    }
    return null;
  }

  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }



  //add skills to the array
  addSkillsToQueue() {


    this.skills.unshift(
      {
        skill: this.myControl.value
      }
    );
    this.myControl.setValue('');
    console.log(this.skills);

  }


  removeSkillsItem(i) {
    this.skills.splice(i, 1)
  }



}

