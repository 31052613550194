import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, ErrorStateMatcher } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../services/user/user.service'


@Component({
  selector: 'app-membership-of-association',
  templateUrl: './membership-of-association.component.html',
  styleUrls: ['./membership-of-association.component.scss']
})
export class MembershipOfAssociationComponent implements OnInit {
  MembershipOfAssociation: FormGroup;
  constructor(private frmBldr: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog, private usrSrv: UserService,) { }

  selectedMembershipDoc;
  membershipFile
  load = false;
  activerUser
  ngOnInit() {
    this.usrSrv.getUser().subscribe(resp => {
      this.activerUser = resp;
      console.log(resp)
    })
    this.MembershipOfAssociation = this.frmBldr.group({
      organizationName: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2)
        ]
      ],
      position: [null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2)

        ]],
    })
  }
  getMembershipPresignedurls(): Promise<any> {


    return new Promise((resolve, reject) => {

      this.usrSrv.getMembershipPresignedurls(this.selectedMembershipDoc, "membershipCertificate").then(presignUrl => {
        //console.log(presignUrl);

        var formData: any = new FormData();

        Object.keys(presignUrl['presignedPost']['fields']).forEach(key => {
          formData.append(key, presignUrl['presignedPost']['fields'][key])
        });

        this.selectedMembershipDoc.key = presignUrl.id;
        formData.append('file', this.selectedMembershipDoc.file);
        //console.log("formdata", formData);
        this.usrSrv.membershipCertificateUploadFile(presignUrl.presignedPost.url, formData).then(
          data => {
            //console.log(data);

            resolve(presignUrl);
          }, error => {
            //console.log(error);
            reject(error)

          }
        ).catch(
          error => {
            //console.log(error);

          }
        );

      }).catch(
        error => {
          //console.log(error)
        }

      )

    });
  }



  onMembershipSelectedDocuments(event) {
    let file = event.target.files[0];
    let filename = file.name;
    let extension = filename.split('.');
    extension = extension[extension.length - 1]

    this.selectedMembershipDoc = {
      file: file,
      fileName: filename,
      fileExtension: extension,
    }
    console.log(this.selectedMembershipDoc)
  }

  submitMembership() {
    this.load = true;

    if (this.selectedMembershipDoc != null) {

      this.getMembershipPresignedurls().then(data => {

        this.membershipFile = {
          key: data.id,
          fileName: this.selectedMembershipDoc.fileName,
          extension: this.selectedMembershipDoc.fileExtension
        }
        let membership = {
          userId: this.activerUser.id,
          organization: this.MembershipOfAssociation.value.organizationName,
          position: this.MembershipOfAssociation.value.position,
          certificate: this.membershipFile,
        };

        this.usrSrv.createMembership(membership).then(data => {
          console.log(data);
          this.load = false;
          this.myAlert("Membership of association Submited success updated successfully");
          this.closeDialog()
          //window.location.reload();
        })


        //console.log(this.user.slaObject);
        // let userId = this.user.id;
        // this.userSrvc.updateUser(userId, this.user).then(
        //   resp => {
        //     //console.log(resp);
        //     this.loading = false;
        //     this.myAlert("CV updated successfully");

        //   }
        // ).catch(
        //   error => {
        //     //console.log(error);

        //     this.myAlert("Your details could not be updated, please try again later");
        //     this.loading = false;

        //   }
        // );
      }).catch(
        error => {
          //console.log(error);

          this.myAlert("Your details could not be updated, please try again later");
          this.load = false;

        }
      );
    }
    // else {
    //   let ProoffessionaRegistration = {
    //     userId: this.activerUser.id,
    //     professional: this.ProffessionalRegistrationForm.value.QualificationName,
    //     professionalBody: this.ProffessionalRegistrationForm.value.ProffessionalBodyName,
    //     professionalFile: null,
    //   };

    //   this.usrSrv.createProffessionalRegistration(ProoffessionaRegistration).then(data => {
    //     console.log(data);
    //     this.load = false;
    //       this.myAlert("Professional Registraion Submited success updated successfully");
    //   })
    // }






  }
  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['blue-snackbar']
    });

  }
  // // openProffessionalRegistrationDialog(): void {
  //   let dialogReff = this.dialog.open(ProffessionalRegistrationComponent, {
  //      height: '500px',
  //      width: '600px',

  //    });

  // //  }
  closeDialog() {
    this.dialog.closeAll()
  }
}
