import { Component, OnInit } from '@angular/core';
import { IndustriesService } from '../../../services/industries/industries.service';
import { ServiceTypesService } from '../../../services/service-types/service-types.service';
import { SpecialistsService } from '../../../services/specialists/specialists.service';
import { UserService } from "../../../services/user/user.service";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  perIndustry = {
    data: [],
    chartLabel: 'Per discipline 1'
  };

  perServiceType = {
    data: [],
    chartLabel: 'Per Service Type'
  };

  newAccounts = new Array;
  specialists = new Array;
  totalAccount: number = 0;

  disciplines = [];
  serviceTypes = [];


  userServiceTypes;
  userDisciplines;

  constructor(
    private specialistsService: SpecialistsService,
    private industriesService: IndustriesService,
    private serviceTypeService: ServiceTypesService,
    private userService: UserService
  ) {



    this.specialistsService.getSpecialists().subscribe(
      resp => {
        this.specialists = resp;

        this.countAccounts();
      }
    );
    this.industriesService.getIndustries().subscribe(
      disciplines => {
        console.log(disciplines)
        this.disciplines = disciplines
        disciplines.forEach(element => {
          this.perIndustry.data.push(
            {
              label: element.discipline,
              value: 0
            }
          )
        })
        //count stats for charts

      }
    );

    this.industriesService.getUsersIndustries().subscribe(
      resp => {
        this.userDisciplines = resp;
        this.perIndustryCount();

      }
    )
    //get service types
    this.serviceTypeService.getServiceTypes().subscribe(
      serviceTypes => {
        console.log(serviceTypes);
        this.serviceTypes = serviceTypes;
        serviceTypes.forEach(element => {
          this.perServiceType.data.push(
            {
              label: element.serviceType,
              value: 0
            }
          )
        })
        //count stats for charts

      }
    );

    this.serviceTypeService.getUsersServiceTypes().subscribe(
      resp => {
        this.userServiceTypes = resp;
        this.perServiceTypeCount();
      }
    )


  }

  ngOnInit() {
  }

  perIndustryCount() {
    for (let i = 0; i < this.specialists.length; i++) {
      if (this.specialists[i].disciplines != null) {
        for (let x = 0; x < this.disciplines.length; x++) {
          for (let j = 0; j < this.specialists[i].disciplines.length; j++) {
            if (this.specialists[i].disciplines[j].id === this.disciplines[x].id) {
              this.perIndustry.data[x].value++;
            }
          }
        }
      }

    }
    console.log("===============industry============", this.perIndustry)

  }

  perServiceTypeCount() {
    for (let i = 0; i < this.specialists.length; i++) {

      if (this.specialists[i].serviceTypes != null) {
        this.countServiceType(this.specialists[i].serviceTypes)
      }
    }

    console.log("===============service count============", this.perServiceType);

  }

  countServiceType(serviceTypes) {
    serviceTypes.forEach(element => {
      for (let x = 0; x < this.serviceTypes.length; x++) {
        if (element.id === this.serviceTypes[x].id) {
          this.perServiceType.data[x].value++;
        }
      }
    });

  }

  countAccounts() {
    this.specialists.forEach(element => {
      let difference = this.differenceInDays(element.createdAt);

      if (difference <= 60) {
        this.newAccounts.push(element)
      }
    });
  }

  differenceInDays(then) {
    let difference = new Date().getTime() - then
    return (((difference / 1000) / 60) / 60 / 24)
  }

}
