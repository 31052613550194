import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FaqService } from 'src/app/services/faq/faq.service';


@Component({
  selector: 'app-industries-dialog',
  templateUrl: './industries-dialog.component.html',
  styleUrls: ['./industries-dialog.component.scss']
})
export class IndustriesDialogComponent implements OnInit {

  industry;
  industryData;
  industryArr = [
    {
      name: "Engineering",
      description: "Primarily deals with the design, manufacture and operation of structures, machines, or devices. It comprises of sectors like civil, industrial, mechanical, and chemical."
    },
    {
      name: "Manufacturing",
      description: "Sector of the economy that produces finished products. It can be compared to primary industries that produce raw materials and service industries that produce intangible value."
    },
    {
      name: "Agriculture",
      description: "Includes enterprises engaged in growing crops, raising fish and animals, and logging wood. It encompasses of farms, dairies, hatcheries, and ranches."
    },
    {
      name: "Mining",
      description: "The branch of manufacture and trade based on the extraction of ores, fossil fuels, minerals, stone, clay, gravel, and similar commodities."
    },
    {
      name: "Plastics",
      description: "The plastics industry manufactures polymer materials commonly called plastics and offers services in plastics important to a range of industries, including packaging, building and construction, electronics, aerospace, and transportation. It is part of the chemical industry.",
      readMoreLink: "Plastic"
    },
    {
      name: "Energy",
      description: "The totality of all of the industries involved in the production and sale of energy, including fuel extraction, manufacturing, refining and distribution."
    },
    {
      name: "Healthcare",
      description: "An aggregation and integration of sectors within the economic system that provides goods and services to treat patients with curative, preventive, rehabilitative, and palliative care. It includes the generation and commercialization of goods and services lending themselves to maintaining and re-establishing health."
    },
    {
      name: "Information and Communications Technology",
      description: "ICT’s role is to unify communications and the integration of telecommunications as well as computers. It covers any product that will store, retrieve, manipulate, transmit, or receive information electronically in a digital form."
    },
    {
      name: "Government Service Delivery",
      description: "The delivery of public services to citizens, residents, or enterprises. "
    },
    {
      name: "Water & Sanitation",
      description: "The process of cleaning and purifying water so it is safe for use. "
    }
  ]
  loader: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private faqSrvc: FaqService

  ) {
    console.log(data);
    this.industry = data;
  }
  ngOnInit() {
    // this.faqSrvc.getAllIndustries().then(data =>{
    //   this.industryArr = data;
    //   this.industryArr = this.industryArr.industries

    //   console.log(data)
    // }).catch(error =>{
    //   console.log(error)
    // });
    this.industryArr.forEach(
      element => {
        if (element.name == this.industry) {
          this.industryData = element;
        }
      }
    );

  }
  showLoader() {

    this.loader = true
    setInterval(() => {
      this.loader = false
    }, 1200)

  }

}
