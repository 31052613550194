import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SpecializationTagsService {

  private baseUrl = environment.baseUrl;
  private apiAuthToken = environment.apiAuthToken;
  private headers = new HttpHeaders({ 'Authorization': this.apiAuthToken });

  constructor(private http: HttpClient) { }

  getAllService(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}lookups/serviceTypes`, { headers: this.headers }).toPromise();
  }
  getAllDesciplines(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}lookups/discipline`, { headers: this.headers }).toPromise();
  }


  getAllDepartment(disciplineId) {
    return this.http.get(`${this.baseUrl}lookups/departments`, {
      headers: this.headers,
      params: new HttpParams().set('disciplineId', disciplineId)
    }).toPromise();
  }


  getUserServiceType(userId) {
    return this.http.get<any>(`${this.baseUrl}lookups/userServiceTypes`, { headers: this.headers, params: new HttpParams().set('userId', userId) }).toPromise();
  }



}
