import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  mobile = false;
  isOpened = false;
  constructor() { }

  ngOnInit() {
   
    if (window.innerWidth < 680) {
      this.mobile = true;
    }
    $(window).scroll( function () {

      var wScroll = $(window).scrollTop();
      
      if (wScroll >= 10) {
        $('.nav-wrapper').addClass('sticky-nav');

      } else {
        $('.nav-wrapper').removeClass('sticky-nav');

      }

    });
  }
  toggleMobileMenu() {

    if (this.isOpened) {
      this.isOpened = false;
    } else {
      this.isOpened = true;
    }
  }
  scroll(el: HTMLElement) {

    window.scrollTo({
      top: el.offsetTop - 80,
      behavior: "smooth"
    });
  }

}
