import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetPassword: FormGroup;
  submitted = null;
  options = { autoHide: false, scrollbarMinSize: 70 };

  constructor(private formBldr: FormBuilder, private authSrvc: AuthService) { }

  ngOnInit() {
    this.resetPassword = this.formBldr.group({
      emailAddress: [null,
        [
          Validators.required,
          Validators.maxLength(255)
        ]
      ],
    });
  }

  submitQuery() {
    this.submitted = "sending";

    let emailAddress = this.resetPassword.value.emailAddress;

    this.authSrvc.resetPassword(emailAddress).then(
      resp => {
        //console.log(resp);
        this.submitted = "done"; 
        
      }
    ).catch(error => {
      //console.log(error);
      this.submitted = "failed"
    });
   
  }

}
